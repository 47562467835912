import router from "@/router";
import {notify} from "@kyvg/vue3-notification";
import {serverError, serverFailedAccess} from "@/main";
import {API_URL} from "@/core/constants";

export default {
    state: {
        documents: [],
        customDocuments: [],
        documentById: {},
        registerPage: 1,
        registerMaxPage: 0,
        customDocumentsPage: 1,
        customDocumentsMaxPage: 0,
    },
    getters: {
        getDocuments(state) {
            return state.documents;
        },
        getCustomDocuments(state) {
            return state.customDocuments;
        },
        getDocumentById(state) {
            return state.documentById;
        }
    },
    mutations: {
        updateDocuments(state, documents) {
            state.documents = documents;
        },
        chooseCurrentDocument(state, documentId) {
            state.documents.map((document) => {
                if (document.id === parseInt(documentId)) {
                    this.commit('updateCurrentDocInfo', document);
                }
            });
        },
        updateCustomDocuments(state, documents) {
            state.customDocuments = documents;
        },
        updateDocumentById(state, document) {
            state.documentById = document;
        },
        setRegisterPage(state, page) {
            state.registerPage = page
        },
        setRegisterDocument(state, document) {
            state.documents = document
        },
        setRegisterMaxPage(state, page) {
            state.registerMaxPage = page
        },
        setCustomDocumentsPage(state, page) {
            state.customDocumentsPage = page
        },
        setCustomDocumentsDocument(state, document) {
            state.customDocuments = document
        },
        setCustomDocumentsMaxPage(state, page) {
            state.customDocumentsMaxPage = page
        },
    },
    actions: {
        async fetchDocuments(ctx) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/documents`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`
                    }
                }
            )
                .then((response) => {
                    if (response.ok) {
                        response.json().then((response) => {
                            ctx.commit('updateDocuments', response.data.items);
                            ctx.commit('setRegisterMaxPage', response.data.last_page);
                            ctx.commit('setRegisterPage', 1);
                        })
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async fetchMoreDocuments(ctx, payload) {
            ctx.commit('setRegisterPage', ctx.state.registerPage + 1)
            if (ctx.state.registerPage <= ctx.state.registerMaxPage) {
                await fetch(
                    `${API_URL}managers/channels/${localStorage.channelId}/documents?page=${ctx.state.registerPage}&search=${payload.searchValue}&sort_date=${payload.sortDate}&sort_number=${payload.sortNumber}`,
                    {
                        method: 'GET',
                        headers: {
                            Authorization: `${localStorage.token_type} ${localStorage.access_token}`
                        }
                    }
                )
                    .then((response) => {
                        if (response.ok) {
                            response.json().then((response) => {
                                ctx.commit('setRegisterDocument', [...ctx.state.documents, ...response.data.items]);
                            })
                        } else {
                            serverError(response);
                        }
                    })
                    .catch(() => {
                        serverFailedAccess();
                    });
            }
        },
        async searchDocuments(ctx, payload) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/documents?type_id=${payload.typeId}&search=${payload.search}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`
                    }
                }
            )
                .then((response) => {
                    if (response.ok) {
                        response.json().then((response) => {
                            ctx.commit('updateDocuments', response.data.items);
                        })
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async sortDocuments(ctx, payload) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/documents?sort_date=${payload.sortDate}&sort_number=${payload.sortNumber}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`
                    }
                }
            )
                .then((response) => {
                    if (response.ok) {
                        response.json().then((response) => {
                            ctx.commit('updateDocuments', response.data.items);
                        })
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async getDocumentById(ctx, payload) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/documents/${payload}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`
                    }
                }
            )
                .then((response) => {
                    if (response.ok) {
                        response.json().then((response) => {
                            ctx.commit('updateDocumentById', response.data);

                            router.push('/document-constructor');
                        })
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async deleteDocuments(ctx, payload) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/documents/${payload.docId}`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`
                    },
                    body: payload.data
                }
            )
                .then((response) => {
                    if (response.ok) {
                        this.dispatch('fetchDocuments');
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async fetchCustomDocuments(ctx) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/documents/customs`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`
                    }
                }
            )
                .then((response) => {
                    if (response.ok) {
                        response.json().then((response) => {
                            ctx.commit('updateCustomDocuments', response.data.items);
                            ctx.commit('setCustomDocumentsMaxPage', response.data.last_page);
                            ctx.commit('setCustomDocumentsPage', 1);
                        })
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async fetchMoreCustomDocuments(ctx, payload) {
            ctx.commit('setCustomDocumentsPage', ctx.state.customDocumentsPage + 1)
            if (ctx.state.customDocumentsPage <= ctx.state.customDocumentsMaxPage) {
                await fetch(
                    `${API_URL}managers/channels/${localStorage.channelId}/documents/customs?page=${ctx.state.customDocumentsPage}&search=${payload.searchValue}&sort_date=${payload.sortDate}`,
                    {
                        method: 'GET',
                        headers: {
                            Authorization: `${localStorage.token_type} ${localStorage.access_token}`
                        }
                    }
                )
                    .then((response) => {
                        if (response.ok) {
                            response.json().then((response) => {
                                ctx.commit('setCustomDocumentsDocument', [...ctx.state.customDocuments, ...response.data.items]);
                            })
                        } else {
                            serverError(response);
                        }
                    })
                    .catch(() => {
                        serverFailedAccess();
                    });
            }
        },
        async searchCustomDocuments(ctx, payload) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/documents/customs?&search=${payload}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`
                    }
                }
            )
                .then((response) => {
                    if (response.ok) {
                        response.json().then((response) => {
                            ctx.commit('updateCustomDocuments', response.data.items);
                        })
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async uploadCustomDocuments(ctx, payload) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/documents/customs`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`
                    },
                    body: payload
                }
            )
                .then((response) => {
                    if (response.ok) {
                        this.dispatch('fetchCustomDocuments');
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async sendCustomDocuments(ctx, payload) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/documents/customs/${payload.documentId}/send`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`
                    },
                    body: payload.data
                }
            )
                .then((response) => {
                    if (response.ok) {
                        notify({
                            type: 'success',
                            text: 'Документ успешно отправлен!',
                            speed: 1000,
                            duration: 5000
                        });
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async deleteCustomDocuments(ctx, payload) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/documents/customs/${payload.docId}`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`
                    },
                    body: payload.data
                }
            )
                .then((response) => {
                    if (response.ok) {
                        this.dispatch('fetchCustomDocuments');
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async sortCustomDocuments(ctx, payload) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/documents/customs?&sort_date=${payload.sortDate}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`
                    }
                }
            )
                .then((response) => {
                    if (response.ok) {
                        response.json().then((response) => {
                            ctx.commit('updateCustomDocuments', response.data.items);
                        })
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },
    }
}