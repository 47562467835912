<template>
  <div>
    <div class="header">
      <router-link to="/"><img alt="Обращайся" src="@/assets/img/obr-logo.svg"></router-link>
    </div>
    <div class="body">
      <img v-if="windowWidth <= 680" alt="" class="mobile-bg" src="@/views/Landing/images/common/accept.svg">

      <div>
        <span class="title">
          Ваша организация находится на проверке
        </span>
        <span class="text">
          Нашим специалистам необходимо проверить все данные Вашей организации и убедиться, что всё в порядке.
          Как только проверка закончится, мы свяжемся с Вами и Вы сможете закончить создание канала.
        </span>
        <span class="text2">Свяжемся с Вами в ближайшее время (1 рабочий день).</span>
        <span class="text2" style="margin-top: 20px;"><span class="text2" style="color: var(--landing-color-red)">ССЫЛКА , ЛОГИН И ПАРОЛЬ</span> ПОСТУПЯТ НА ВАШУ ЭЛЕКТРОННУЮ ПОЧТУ </span>
        <span class="text2" style="margin-top: 20px;">МЫ РАДЫ ВАМ!</span>
      </div>
      <div v-if="windowWidth > 680" class="img-div">
        <img alt="" src="@/views/Landing/images/common/accept.svg">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModerationOrganization',
  data() {
    return {
      windowWidth: 0,
    }
  },
  created() {
    this.windowWidth = window.innerWidth;
  },
}
</script>

<style lang="scss" scoped>
.header {
  height: 130px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--color-white);

  a {
    margin-left: 40px;
  }
}

.body {
  background-color: var(--message-color-input);
  height: calc(100dvh - 130px);
  font-family: 'SF Pro Display Regular';
  display: grid;
  grid-template-columns: 40% 60%;
  align-items: center;

  & > div:not(.img-div) {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    width: 500px;
    margin-left: 196px;

    span.title {
      font-family: 'SF Pro Display';
      font-weight: 800;
      font-size: 42px;
      line-height: 52px;
      color: var(--color-dark-blue);
      margin-bottom: 25px;
    }

    span:not(.title) {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: var(--figma-color-dark-grey);
    }

    .text {
      margin-bottom: 50px;
    }
  }

  .img-div {
    max-height: 50dvh;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    img {
      height: 100%;
      max-height: 50dvh;
      width: 100%;
      object-fit: contain;
    }
  }
}

@media (max-width: 1540px) {
  .header {
    height: 90px;
  }

  .body {
    height: calc(100dvh - 90px);
    padding: 25px 0 50px 40px;

    & > div:not(.img-div) {
      margin-left: 13%;
      width: 360px;

      span.title {
        font-style: normal;
        font-weight: 800;
        font-size: 30px;
        margin-bottom: 15px;
      }

      span:not(.title) {
        font-size: 11px;
        line-height: 17px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .body {
    grid-template-columns: 100%;
    padding: 95px 0 0 0;

    & > div:not(.img-div) {
      margin: 0 auto;
      text-align: center;
      width: 500px;
      align-items: center;

      span.title {
        font-style: normal;
        font-weight: 800;
        font-size: 42px;
        margin-bottom: 52px;
      }

      span:not(.title) {
        font-size: 16px;
        line-height: 150%;
      }
    }
  }
}

@media (max-width: 680px) {
  .mobile-bg {
    width: 100%;
    height: 90%;
  }

  .body {
    padding: 0 15px 0;

    & > div:not(.img-div) {
      margin: -100px auto 0;
      text-align: center;
      width: 100%;

      span.title {
        font-style: normal;
        font-weight: 800;
        font-size: 32px;
        margin-bottom: 41px;
      }

      span:not(.title) {
        font-size: 14px;
        line-height: 150%;
      }
    }

    .img-div {
      display: none;
    }
  }
}
</style>