import {serverError, serverFailedAccess} from "@/main";
import {notify} from "@kyvg/vue3-notification";
import {API_URL} from "@/core/constants";

export default {
    state: {
        receivingHistory: [],
        walletPage: 1,
        maxWalletPage: 0,
        walletTotalBalance: '',
        deductionHistory: [],
        cards: [],
    },
    getters: {
        getReceivingHistory(state) {
            return state.receivingHistory;
        },
        getWalletTotalBalance(state) {
            return state.walletTotalBalance;
        },
        getDeductionHistory(state) {
            return state.deductionHistory;
        },
        getCards(state) {
            return state.cards;
        },
    },
    mutations: {
        updateReceivingHistory(state, history) {
            state.receivingHistory = history;
        },
        setReceivingHistory(state, card) {
            state.receivingHistory = card;
        },

        updateWalletTotalBalance(state, balance) {
            state.walletTotalBalance = balance;
        },

        setWalletPage(state, page) {
            state.walletPage = page
        },
        setWalletMaxPage(state, page) {
            state.maxWalletPage = page
        },

        updateDeductionHistory(state, history) {
            state.deductionHistory = history
        },

        updateCards(state, cards) {
            state.cards = cards;
        },
        addNewCardForWallet(state, card) {
            state.cards.push(card);
        },
        deleteCardForWallet(state, cardId) {
            state.cards.map((card, index) => {
                if (card.id === parseInt(cardId)) {
                    state.cards.splice(index, 1);
                }
            });
        },
    },
    actions: {
        async fetchWalletReceivingHistory(ctx) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/payments`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
                    }
                }
            ).then((response) => {
                if (response.ok) {
                    response.json().then((response) => {
                        ctx.commit('updateReceivingHistory', response.data.items)
                        ctx.commit('setWalletMaxPage', response.data.last_page)
                        ctx.commit('setWalletPage', 1)
                    })
                } else {
                    serverError(response);
                }
            })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async fetchMoreWalletReceivingHistory(ctx) {
            ctx.commit('setWalletPage', ctx.state.walletPage + 1)

            if (ctx.state.walletPage <= ctx.state.maxWalletPage) {
                await fetch(
                    `${API_URL}managers/channels/${localStorage.channelId}/payments`,
                    {
                        method: 'GET',
                        headers: {
                            Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
                        }
                    }
                ).then((response) => {
                    if (response.ok) {
                        response.json().then((response) => {
                            ctx.commit('setReceivingHistory', [...ctx.state.receivingHistory, ...response.data.items])
                        })
                    } else {
                        serverError(response);
                    }
                })
                    .catch(() => {
                        serverFailedAccess();
                    });
            }
        },

        async fetchWalletTotalBalance(ctx) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/payments/balance`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
                    }
                }
            ).then((response) => {
                if (response.ok) {
                    response.json().then((response) => {
                        ctx.commit('updateWalletTotalBalance', response.data.balance)
                    })
                } else {
                    serverError(response);
                }
            })
                .catch(() => {
                    serverFailedAccess();
                });
        },

        // async fetchWalletDeductionHistory(ctx) {
        //     await fetch(
        //         `${API_URL}managers/channels/${localStorage.channelId}/payments/mine`,
        //         {
        //             method: 'GET',
        //             headers: {
        //                 Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
        //             }
        //         }
        //     ).then((response) => {
        //         if (response.ok) {
        //             response.json().then((response) => {
        //                 ctx.commit('updateDeductionHistory', response.data)
        //             })
        //         } else {
        //             serverError(response);
        //         }
        //     })
        //         .catch(() => {
        //             serverFailedAccess();
        //         });
        // },

        async fetchCards(ctx) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/payments/methods`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
                    }
                }
            ).then((response) => {
                if (response.ok) {
                    response.json().then((response) => {
                        ctx.commit('updateCards', response.data)
                    })
                } else {
                    serverError(response);
                }
            })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async addCardWallet(ctx, payload) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/payments/methods`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
                    },
                    body: payload
                }
            ).then((response) => {
                if (response.ok) {
                    response.json().then((response) => {
                        ctx.commit('addNewCardForWallet', response.data);

                        notify({
                            type: 'success',
                            text: 'Карта успешно добавлена!',
                            speed: 1000,
                            duration: 5000
                        });
                    })
                } else {
                    serverError(response);
                }
            })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async deleteWalletCard(ctx, payload) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/payments/methods/${payload.cardId}`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
                    },
                    body: payload.data
                }
            ).then((response) => {
                if (response.ok) {
                    ctx.commit('deleteCardForWallet', payload.cardId);

                    notify({
                        type: 'success',
                        text: 'Карта успешно удалена!',
                        speed: 1000,
                        duration: 5000
                    });
                } else {
                    serverError(response);
                }
            })
                .catch(() => {
                    serverFailedAccess();
                });
        },

        async moneyTransfer(ctx, payload) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/payments`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
                    },
                    body: payload
                }
            ).then((response) => {
                if (response.ok) {
                    response.json().then(() => {
                        ctx.dispatch('fetchWalletTotalBalance');
                        ctx.dispatch('fetchWalletReceivingHistory');

                        notify({
                            type: 'success',
                            text: 'Деньги успешно отправлены!',
                            speed: 1000,
                            duration: 5000
                        });
                    })
                } else {
                    serverError(response);
                }
            })
                .catch(() => {
                    serverFailedAccess();
                });
        },
    }
}