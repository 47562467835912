<template>
  <CreateOrganization
      v-if="showCreateOrganization"
      :type="'federal'"
      @switchCreateOrgLayout="switchCreateOrgLayout"
  ></CreateOrganization>
</template>

<script>
import CreateOrganization from '@/views/Registration/CreateOrganization';
import {mapGetters} from "vuex";

export default {
  data: () => ({
    showCreateOrganization: true
  }),
  name: 'FederalRegister',
  components: {
    CreateOrganization
  },
  created() {
    if (this.getUserData?.secret_id === undefined) {
      this.$router.push('/authorization');
    }
  },
  computed: {
    ...mapGetters(
        [
          'getUserData',
        ]
    ),
    switchCreateOrgLayout() {
      return () => {
        this.showCreateOrganization = !this.showCreateOrganization;
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>