import {serverError, serverFailedAccess} from "@/main";
import {API_URL} from "@/core/constants";

export default {
  state: {
    publicOrderList: [],

    //community
    publicChannelList: [],
    channelsForPublicPage: 0,
    channelsForPublicMaxPage: 0,
    channelsForCommunityTotalPage: 0,
    publicChannelTypeList: [],
    publicRegionsList: [],
    publicFieldList: [],
    publicThemeList: [],
    publicAdminThemeList: [],
  },
  getters: {
    getPublicOrderList(state) {
      return state.publicOrderList;
    },

    //community
    getPublicChannelList(state) {
      return state.publicChannelList;
    },
    getPublicChannelTypeList(state) {
      return state.publicChannelTypeList;
    },
    getPublicRegionsList(state) {
      return state.publicRegionsList;
    },
    getPublicFieldList(state) {
      return state.publicFieldList;
    },
    getPublicThemeList(state) {
      return state.publicThemeList;
    },
    getPublicAdminThemeList(state) {
      return state.publicAdminThemeList;
    },
    getPublicCurrentPage(state) {
      return state.channelsForPublicPage;
    },
  },
  mutations: {
    updatePublicOrderList(state, data) {
      state.publicOrderList = data;
    },

    //community
    updatePublicChannelList(state, data) {
      state.publicChannelList = data;
    },
    setChannelsListForPublicPage(state, pageNumber) {
      state.channelsForPublicPage = pageNumber;
    },
    setChannelsListForPublicMaxPage(state, maxPage) {
      state.channelsForPublicMaxPage = maxPage;
    },
    updateChannelsListForPublicTotalCount(state, totalCount) {
      state.channelsForCommunityTotalPage = totalCount;
    },
    updatePublicChannelTypeList(state, data) {
      state.publicChannelTypeList = data;
    },
    updatePublicRegionsList(state, data) {
      state.publicRegionsList = data;
    },
    updatePublicFieldList(state, data) {
      state.publicFieldList = data;
    },
    updatePublicThemeList(state, data) {
      state.publicThemeList = data;
    },
    updatePublicAdminThemeList(state, data) {
      state.publicAdminThemeList = data;
    },
  },
  actions: {
    async fetchPublicOrderList(ctx) {
      await fetch(
          `${API_URL}managers/trade/orders`,
          {
            method: 'GET',
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('updatePublicOrderList', response.data);
              });
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },

    //community
    async fetchPublicChannelList(ctx, payload) {
      if (ctx.state.channelsForPublicPage !== 0) {
        ctx.commit('setChannelsListForPublicPage', ctx.state.channelsForPublicPage + 1);
      }

      if (ctx.state.channelsForPublicPage <= ctx.state.channelsForPublicMaxPage) {
        await fetch(
            `${API_URL}managers/channels/all?${payload}`,
            {
              method: 'GET',
            }
        )
            .then((response) => {
              if (response.ok) {
                response.json().then((response) => {
                  if (ctx.state.channelsForPublicPage === 0) {
                    ctx.commit('updatePublicChannelList', response.data.items);
                    ctx.commit('setChannelsListForPublicMaxPage', response.data.last_page);
                    ctx.commit('setChannelsListForPublicPage', 1);
                  } else {
                    ctx.commit('updatePublicChannelList', [...ctx.state.publicChannelList, ...response.data.items]);
                  }

                  ctx.commit('updateChannelsListForPublicTotalCount', response.data.total);
                })
              } else {
                serverError(response);
              }
            })
            .catch(() => {
              serverFailedAccess();
            });
      }
    },
    async fetchPublicChannelTypeList(ctx) {
      await fetch(
          `${API_URL}managers/channels/types/all`,
          {
            method: 'GET',
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('updatePublicChannelTypeList', response.data);
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async fetchPublicRegionsList(ctx) {
      await fetch(
          `${API_URL}managers/channels/regions`,
          {
            method: 'GET',
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                const regions = response.data;
                let dataRegions = [];

                regions.forEach((region) => {
                  dataRegions.push({id: region.region, name: region.region});
                });

                ctx.commit('updatePublicRegionsList', dataRegions);
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async fetchPublicFieldList(ctx) {
      await fetch(
          `${API_URL}managers/scopes/short`,
          {
            method: 'GET',
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('updatePublicFieldList', response.data);
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async fetchPublicThemeList(ctx) {
      await fetch(
          `${API_URL}managers/scopes/short-themes`,
          {
            method: 'GET',
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('updatePublicThemeList', response.data);
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async fetchPublicAdminThemeList(ctx) {
      await fetch(
          `${API_URL}managers/scopes/short-administrative`,
          {
            method: 'GET',
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('updatePublicAdminThemeList', response.data);
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
  }
}