import books from "@/store/modules/books/books";
import records from "@/store/modules/books/records";
import residents from "@/store/modules/books/residents";
import municipality from "@/store/modules/books/municipality";
import {API_URL} from "@/core/constants";
import {serverError, serverFailedAccess} from "@/main";

export default {
  state: {
    channelInfo: {},
    nationalities: [],
    citizenship: [],
    farms: [],
    citizens: [],
    citizensShort: [],
    houseHold: [],
    houseHoldTypes: [],
  },
  getters: {
    getBooksChannelInfo(state) {
      return state.channelInfo;
    },
    getBooksNationalities(state) {
      return state.nationalities;
    },
    getBooksCitizenship(state) {
      return state.citizenship;
    },
    getBooksFarms(state) {
      return state.farms;
    },
    getBooksCitizens(state) {
      return state.citizens;
    },
    getBooksCitizensShort(state) {
      return state.citizensShort;
    },
    getBooksHouseHold(state) {
      return state.houseHold;
    },
    getBooksHouseHoldTypes(state) {
      return state.houseHoldTypes;
    },
  },
  mutations: {
    updateBooksChannelInfo(state, info) {
      state.channelInfo = info;
    },
    updateBooksNationalities(state, nationalities) {
      state.nationalities = nationalities;
    },
    updateBooksCitizenship(state, citizenship) {
      state.citizenship = citizenship;
    },
    updateBooksFarms(state, farms) {
      state.farms = farms;
    },
    updateBooksCitizens(state, citizens) {
      state.citizens = citizens;
    },
    updateBooksCitizensShort(state, citizensShort) {
      state.citizensShort = citizensShort;
    },
    updateBooksHouseHold(state, houseHold) {
      state.houseHold = houseHold;
    },
    updateBooksHouseHoldTypes(state, houseHoldTypes) {
      state.houseHoldTypes = houseHoldTypes;
    },
  },
  actions: {
    async fetchBooksChannelInfo(ctx) {
      await fetch(
          `${API_URL}managers/channels/${localStorage.channelId}`,
          {
            method: 'GET',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            }
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('updateBooksChannelInfo', response.data);
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async fetchBooksNationalities(ctx) {
      await fetch(
          `${API_URL}managers/nationalities`,
          {
            method: 'GET',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            }
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('updateBooksNationalities', response.data);
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async fetchBooksCitizenship(ctx) {
      await fetch(
          `${API_URL}managers/citizenship`,
          {
            method: 'GET',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            }
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('updateBooksCitizenship', response.data);
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async fetchBooksFarms(ctx) {
      await fetch(
          `${API_URL}managers/channels/${localStorage.channelId}/municipalities/farms`,
          {
            method: 'GET',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            }
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('updateBooksFarms', response.data);
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async fetchBooksCitizens(ctx) {
      await fetch(
          `${API_URL}managers/channels/${localStorage.channelId}/municipalities/citizens`,
          {
            method: 'GET',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            }
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('updateBooksCitizens', response.data);
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async fetchBooksCitizensShort(ctx) {
      await fetch(
          `${API_URL}managers/channels/${localStorage.channelId}/municipalities/citizens/short`,
          {
            method: 'GET',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            }
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('updateBooksCitizensShort', response.data);
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async fetchBooksHouseHold(ctx) {
      await fetch(
          `${API_URL}managers/channels/${localStorage.channelId}/house-hold-books`,
          {
            method: 'GET',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            }
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('updateBooksHouseHold', response.data);
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async fetchBooksHouseHoldTypes(ctx) {
      await fetch(
          `${API_URL}managers/channels/${localStorage.channelId}/house-hold-books/types`,
          {
            method: 'GET',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            }
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('updateBooksHouseHoldTypes', response.data);
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
  },
  modules: {
    books,
    records,
    residents,
    municipality,
  },
}