import bloggerModule from '@/store/modules/registration/blogger';
import organizationModule from '@/store/modules/registration/organization';

export default {
    state: {
        registration: []
    },
    getters: {

    },
    mutations: {

    },
    actions: {

    },
    modules: {
        bloggerModule,
        organizationModule
    }
}