import {serverError, serverFailedAccess} from "@/main";
import {API_URL} from "@/core/constants";
import _, {isArray} from "lodash";

export default {
  state: {
    globalDepartmentUsers: [],
    departmentUsers: [],
    sortDepartments: [],
    messagesInRoom: [],
    loaded: false,
    departmentUsersPage: 1,
    departmentUsersMaxPage: 0,
    supportMaxPage: 0,
    supportCurrentPage: 1,
    promoMaxPage: 0,
    promoCurrentPage: 1,
  },
  getters: {
    getLoaded(state) {
      return state.loaded;
    },
    getDepartmentUsers(state) {
      return state.departmentUsers;
    },
    getGlobalDepartmentUsers(state) {
      return state.globalDepartmentUsers;
    },
    getSortDepartmentUsers(state) {
      return state.sortDepartments;
    },
    getDepartmentUserById: (state) => (userId) => {
      if (userId && parseInt(userId) !== 0 && parseInt(userId) !== -1) {
        const selected = state.departmentUsers.find(user => user.id === parseInt(userId));
        return selected ? selected : state.departmentUsers.find(user => user?.room?.id === parseInt(userId));
      } else {
        if (userId && parseInt(userId) === 0) {
          return {
            id: 0,
            name: 'Тех.поддержка'
          };
        }

        if (userId && parseInt(userId) === -1) {
          return {
            id: -1,
            name: 'Реклама'
          };
        }
      }
    },
    getMessagesInRoom(state) {
      return state.messagesInRoom?.messages?.items ? state.messagesInRoom?.messages?.items : state.messagesInRoom?.messages;
    },
    getListRecipientsInRoom(state) {
      return state.messagesInRoom.channels ? state.messagesInRoom.channels : [];
    },
  },
  mutations: {
    updateDepartmentUsers(state, users) {
      state.departmentUsers = users;
    },
    updateDepartmentUserById(state, data) {
      const index = state.departmentUsers.findIndex(user => user.room.id == data.id);

      if (index != -1) {
        let users = state.departmentUsers;
        users = users.filter((user, userIndex) => userIndex !== index);
        users.unshift(state.departmentUsers[index]);
        users[0].room.unread_messages_count++;
        users[0].room.last_message = data.messages[0];

        state.departmentUsers = users;
      } else {
        state.departmentUsers.unshift(data);
      }
    },
    updateGlobalDepartmentUsers(state, users) {
      state.globalDepartmentUsers = users;
    },
    updateMessagesInRoom(state, messages) {
      state.messagesInRoom = messages;
    },
    updateMessagesMaxPage(state, page) {
      state.messagesInRoomMaxPage = page;
    },
    updateMessagesCurrentPage(state, page) {
      state.messagesInRoomCurrentPage = page;
    },
    updateSeenMessages(state) {
      state.messagesInRoom.messages.map(message => {
        if (!message.seen) {
          message.seen = true;
        }
      });
    },
    updateLastMessage(state, data) {
      state.departmentUsers.map((user, index) => {
        if (user.room.id === parseInt(data.roomId)) {
          if (user.room.last_message) {
            user.room.last_message.message = data.message;
            user.room.last_message.created = data.created;
          } else {
            user.room.last_message = {
              message: data.message,
              created: data.created
            }
          }

          state.departmentUsers.unshift(...state.departmentUsers.splice(index, 1));
        }
      });
    },
    getDepartmentUsersByFilter(state, sortBy) {
      let sortByOrganization = sortBy[0];
      const sortBySphere = sortBy[1];

      if (sortByOrganization) {
        sortByOrganization = parseInt(sortByOrganization) === 0 ? 'Административный сектор' : 'Бизнес';
      }

      let byOrganization = (user) => {
        if (sortByOrganization) {
          if (user.type !== null) {
            return user.type.name === sortByOrganization;
          } else {
            return true;
          }
        } else {
          return true;
        }
      };

      let bySphere = (user) => {
        if (sortBySphere) {
          if (user.scope !== null) {
            return user.scope.id === parseInt(sortBySphere);
          } else {
            return true;
          }
        } else {
          return true;
        }
      };

      state.sortDepartments = state.globalDepartmentUsers.filter(user => byOrganization(user) && bySphere(user))
    },
    updateUnreadMessages(state, roomId) {
      state.departmentUsers.map(user => {
        if (user?.room?.id === parseInt(roomId)) {
          user.room.unread_messages_count = 0;
        }
      });
    },
    deleteMessageInRoom(state, messageId) {
      state.messagesInRoom.messages.items = state.messagesInRoom.messages.items.filter(message => message.id.toString() !== messageId.toString());
    },
    updateLoaded(state, loaded) {
      state.loaded = loaded;
    },
    setDepartmentUsersMaxPage(state, page) {
      state.departmentUsersMaxPage = page;
    },
    setDepartmentUsersPage(state, page) {
      state.departmentUsersPage = page
    },

    updateSupportMaxPage(state, page) {
      state.supportMaxPage = page;
    },
    updateSupportCurrentPage(state, page) {
      state.supportCurrentPage = page;
    },

    updatePromoMaxPage(state, page) {
      state.promoMaxPage = page;
    },
    updatePromoCurrentPage(state, page) {
      state.promoCurrentPage = page;
    },
  },
  actions: {
    async fetchDepartmentUsers(ctx) {
      ctx.commit('updateLoaded', true);
      ctx.commit('updateDepartmentUsers', []);

      await fetch(
          `${API_URL}managers/rooms/by-channel/${localStorage.channelId}`,
          {
            method: 'GET',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`
            }
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('updateDepartmentUsers', response.items);
                ctx.commit('setDepartmentUsersMaxPage', response.last_page);
                ctx.commit('setDepartmentUsersPage', 1);

                setTimeout(() => {
                  ctx.commit('updateLoaded', false);
                }, 500);
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async fetchMoreDepartmentUsers(ctx, payload) {
      ctx.commit('updateLoaded', true);
      ctx.commit('setDepartmentUsersPage', ctx.state.departmentUsersPage + 1);

      await fetch(
          `${API_URL}managers/rooms/by-channel/${localStorage.channelId}?page=${ctx.state.departmentUsersPage}&search=${payload}`,
          {
            method: 'GET',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`
            }
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('updateDepartmentUsers', [...ctx.state.departmentUsers, ...response.items]);

                setTimeout(() => {
                  ctx.commit('updateLoaded', false);
                }, 500);
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async searchDepartmentUsers(ctx, payload) {
      ctx.commit('updateDepartmentUsers', []);
      ctx.commit('updateLoaded', true);
      if (payload) {
        await fetch(
            `${API_URL}managers/rooms/${localStorage.channelId}?search=${payload}`,
            {
              method: 'GET',
              headers: {
                Authorization: `${localStorage.token_type} ${localStorage.access_token}`
              }
            }
        )
            .then((response) => {
              if (response.ok) {
                response.json().then((response) => {
                  ctx.commit('updateDepartmentUsers', response.data);

                  setTimeout(() => {
                    ctx.commit('updateLoaded', false);
                  }, 500);
                })
              } else {
                serverError(response);
              }
            })
            .catch(() => {
              serverFailedAccess();
            });
      } else {
        ctx.dispatch('fetchDepartmentUsers');
      }
    },

    async fetchGlobalDepartmentUsers(ctx) {
      await fetch(
          `${API_URL}managers/channels/business`,
          {
            method: 'GET',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`
            }
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('updateGlobalDepartmentUsers', response.data);
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async searchGlobalDepartmentUsers(ctx, payload) {
      if (payload) {
        await fetch(
            `${API_URL}managers/channels/business?search=${payload}`,
            {
              method: 'GET',
              headers: {
                Authorization: `${localStorage.token_type} ${localStorage.access_token}`
              }
            }
        )
            .then((response) => {
              if (response.ok) {
                response.json().then((response) => {
                  ctx.commit('updateGlobalDepartmentUsers', response.data);
                })
              } else {
                serverError(response);
              }
            })
            .catch(() => {
              serverFailedAccess();
            });
      } else {
        ctx.dispatch('fetchDepartmentUsers');
      }
    },

    async getMessageInRoom(ctx, payload) {
      await fetch(
          `${API_URL}managers/rooms/${localStorage.channelId}/${payload}`,
          {
            method: 'GET',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`
            }
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                const data = response.data;
                data.messages.items = data.messages.items.reverse();

                ctx.commit('updateMessagesInRoom', response.data);
                ctx.commit('updateMessagesMaxPage', data.messages.last_page);
                ctx.commit('updateMessagesCurrentPage', data.messages.current_page);
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async getMoreMessageInRoom(ctx, payload) {
      ctx.commit('updateMessagesCurrentPage', ctx.state.messagesInRoomCurrentPage + 1);

      if (ctx.state.messagesInRoomCurrentPage <= ctx.state.messagesInRoomMaxPage) {
        await fetch(
            `${API_URL}managers/rooms/${localStorage.channelId}/${payload}?page=${ctx.state.messagesInRoomCurrentPage}`,
            {
              method: 'GET',
              headers: {
                Authorization: `${localStorage.token_type} ${localStorage.access_token}`
              }
            }
        )
            .then((response) => {
              if (response.ok) {
                response.json().then((response) => {
                  const messages = response.data.messages;
                  const clone = _.cloneDeep(ctx.state.messagesInRoom);

                  clone.messages.items.unshift(...messages.items.reverse());

                  ctx.commit('updateMessagesInRoom', clone);
                })
              } else {
                serverError(response);
              }
            })
            .catch(() => {
              serverFailedAccess();
            });
      }
    },
    async sendMessageInRoom(ctx, payload) {
      await fetch(
          `${API_URL}managers/rooms/${localStorage.channelId}/messages`,
          {
            method: 'POST',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`
            },
            body: payload.data
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                const messages = response.data.messages;
                let clone = _.cloneDeep(ctx.state.messagesInRoom);

                if (isArray(clone) && !clone.length) {
                  clone = {
                    messages: {
                      items: [],
                    },
                  }
                }

                if (clone.messages?.items !== undefined) {
                  clone.messages?.items?.push(messages?.items?.[0]);
                  ctx.commit('updateMessagesInRoom', clone);
                  ctx.commit('updateLastMessage', {
                    message: payload.data.getAll('message')[0],
                    roomId: payload.roomId,
                    created: new Date()
                  });
                  ctx.commit('updateUnreadMessages', payload.roomId);
                }
              })
            } else {
              serverError(response);
              // console.log(response); // TEMPORARY FIX
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async sendMultipleMessageInRoom(ctx, payload) {
      await fetch(
          `${API_URL}managers/rooms/send-multiple`,
          {
            method: 'POST',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`
            },
            body: payload
          }
      )
          .then((response) => {
            if (response.ok) {
              this.dispatch('fetchDepartmentUsers');
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },

    async getSupportMessage(ctx) {
      await fetch(
          `${API_URL}managers/support`,
          {
            method: 'GET',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`
            }
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('updateMessagesInRoom', {messages: response.data.items.reverse()});
                ctx.commit('updateSupportMaxPage', response.data.last_page);
                ctx.commit('updateSupportCurrentPage', response.data.current_page);
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async getMoreSupportMessage(ctx) {
      ctx.commit('updateSupportCurrentPage', ctx.state.supportCurrentPage + 1);

      if (ctx.state.supportCurrentPage <= ctx.state.supportMaxPage) {
        await fetch(
            `${API_URL}managers/support`,
            {
              method: 'GET',
              headers: {
                Authorization: `${localStorage.token_type} ${localStorage.access_token}`
              }
            }
        )
            .then((response) => {
              if (response.ok) {
                response.json().then((response) => {
                  ctx.commit('updateMessagesInRoom', {messages: [...response.data.items.reverse(), ...ctx.state.messagesInRoom.messages]});
                })
              } else {
                serverError(response);
              }
            })
            .catch(() => {
              serverFailedAccess();
            });
      }
    },
    async sendSupportMessage(ctx, payload) {
      await fetch(
          `${API_URL}managers/support`,
          {
            method: 'POST',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`
            },
            body: payload
          }
      )
          .then((response) => {
            if (response.ok) {
              this.dispatch('getSupportMessage');
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },

    async getPromoMessage(ctx) {
      await fetch(
          `${API_URL}managers/support?is_banner=true`,
          {
            method: 'GET',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`
            }
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('updateMessagesInRoom', {messages: response.data.items.reverse()});
                ctx.commit('updatePromoMaxPage', response.data.last_page);
                ctx.commit('updatePromoCurrentPage', response.data.current_page);
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async getMorePromoMessage(ctx) {
      ctx.commit('updatePromoCurrentPage', ctx.state.promoCurrentPage + 1);

      if (ctx.state.promoCurrentPage <= ctx.state.promoMaxPage) {
        await fetch(
            `${API_URL}managers/support?is_banner=true`,
            {
              method: 'GET',
              headers: {
                Authorization: `${localStorage.token_type} ${localStorage.access_token}`
              }
            }
        )
            .then((response) => {
              if (response.ok) {
                response.json().then((response) => {
                  ctx.commit('updateMessagesInRoom', {messages: [...response.data.items.reverse(), ...ctx.state.messagesInRoom.messages]});
                })
              } else {
                serverError(response);
              }
            })
            .catch(() => {
              serverFailedAccess();
            });
      }
    },
    async sendPromoMessage(ctx, payload) {
      await fetch(
          `${API_URL}managers/support`,
          {
            method: 'POST',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`
            },
            body: payload
          }
      )
          .then((response) => {
            if (response.ok) {
              this.dispatch('getPromoMessage');
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },

    async deleteMessage(ctx, payload) {
      await fetch(
          `${API_URL}managers/rooms/${localStorage.channelId}/messages/${payload.messageId}`,
          {
            method: 'POST',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`
            },
            body: payload.data
          }
      )
          .then((response) => {
            if (response.ok) {
              ctx.commit('deleteMessageInRoom', payload.messageId);
            } else {
              serverError(response);
            }
          })
    }
  }
}