import {notify} from "@kyvg/vue3-notification";
import {serverError, serverFailedAccess} from "@/main";
import {API_URL} from "@/core/constants";
// import router from "@/router";

export default {
    state: {
        blogger: []
    },
    getters: {
        getBlogger(state) {
            return state.blogger;
        }
    },
    mutations: {
        updateBlogger(state, blogger) {
            state.blogger = blogger;
        }
    },
    actions: {
        async registerBlogger(ctx, payload) {
            await fetch(
                `${API_URL}managers/auth/register-blogger`,
                {
                    method: 'POST',
                    body: payload
                }
            )
                .then((response) => {
                    if (response.ok) {
                        notify({
                            type: 'success',
                            text: 'Регистрация успешно завершена!',
                            speed: 1000,
                            duration: 5000
                        });

                        response.json().then((response) => {
                            localStorage.access_token = response.data.access_token;
                            localStorage.token_type = response.data.token_type;

                            ctx.commit('updateUserOnline', response.data.manager);

                            this.dispatch('fetchChannels');
                        });
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        }
    }
}