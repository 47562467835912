import {notify} from "@kyvg/vue3-notification";
import {serverError, serverFailedAccess} from "@/main";
import {API_URL} from "@/core/constants";

export default {
    state: {
        stories: [],
        actualStories: [],
        actualGroups: [],
        storiesInGroup: []
    },
    getters: {
        getStories(state) {
            return [...state.stories].reverse();
        },
        getActualStories(state) {
            return [...state.actualStories].reverse();
        },
        getActualGroups(state) {
            return [...state.actualGroups].reverse();
        },
        getActualGroupStories(state) {
            return [...state.storiesInGroup].reverse();
        }
    },
    mutations: {
        updateStories(state, stories) {
            state.stories = stories;
        },
        addNewStory(state, story) {
            state.stories.push(story);
            state.actualStories.push(story);
        },
        updateActualStories(state, stories) {
            state.actualStories = stories;
        },
        deleteStories(state, storyId) {
            state.stories.map((story, index) => {
                if (story.id === parseInt(storyId)) {
                    state.stories.splice(index, 1);
                }
            });

            state.actualStories.map((story, index) => {
                if (story.id === parseInt(storyId)) {
                    state.actualStories.splice(index, 1);
                }
            });
        },
        updateActualGroup(state, groups) {
            state.actualGroups = groups;
        },
        updateActualGroupAfterChange(state, group) {
            state.actualGroups.map((actualGroup, index) => {
                if (actualGroup.id === parseInt(group.id)) {
                    state.actualGroups[index] = group;
                }
            });
        },
        deleteGroup(state, groupId) {
            state.actualGroups.map((group, index) => {
                if (group.id === parseInt(groupId)) {
                    state.actualGroups.splice(index, 1);
                }
            });
        },
        addNewActualGroup(state, group) {
            state.actualGroups.push(group);
        },
        updateActualStoriesInGroup(state, stories) {
            state.storiesInGroup = stories;
        },
    },
    actions: {
        async fetchStories(ctx) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/stories`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`
                    }
                }
            )
                .then((response) => {
                    if (response.ok) {
                        response.json().then((response) => {
                            ctx.commit('updateStories', response.data.items);
                        })
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async fetchActualStories(ctx) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/stories/actual-list`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`
                    }
                }
            )
                .then((response) => {
                    if (response.ok) {
                        response.json().then((response) => {
                            ctx.commit('updateActualStories', response.data);
                        })
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async createStory(ctx, payload) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/stories`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`
                    },
                    body: payload
                }
            )
                .then((response) => {
                    if (response.ok) {
                        notify({
                            type: 'success',
                            text: 'История успешно добавлена!',
                            speed: 1000,
                            duration: 5000
                        });
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async deleteStory(ctx, payload) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/stories/${payload.storyId}`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`
                    },
                    body: payload.data
                }
            )
                .then((response) => {
                    if (response.ok) {
                        ctx.commit('deleteStories', payload.storyId);

                        notify({
                            type: 'success',
                            text: 'История успешно удалена!',
                            speed: 1000,
                            duration: 5000
                        });
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async addStoryInActualGroup(ctx, payload) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/stories/actual/add-to-actual/${payload.groupId}/${payload.storyId}`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`
                    }
                }
            )
                .then((response) => {
                    if (response.ok) {
                        this.dispatch('fetchActualGroup');
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async fetchActualGroup(ctx) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/stories/actual`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`
                    }
                }
            )
                .then((response) => {
                    if (response.ok) {
                        response.json().then((response) => {
                            ctx.commit('updateActualGroup', response.data);
                        })
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async createActualGroup(ctx, payload) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/stories/actual`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`
                    },
                    body: payload
                }
            )
                .then((response) => {
                    if (response.ok) {
                        response.json().then((response) => {
                            ctx.commit('addNewActualGroup', response.data);
                        })
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async changeActualGroup(ctx, payload) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/stories/actual/${payload.groupId}`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`
                    },
                    body: payload.data
                }
            )
                .then((response) => {
                    if (response.ok) {
                        response.json().then((response) => {
                            ctx.commit('updateActualGroupAfterChange', response.data);
                        })
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async deleteActualGroup(ctx, payload) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/stories/actual/${payload.groupId}`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`
                    },
                    body: payload.data
                }
            )
                .then((response) => {
                    if (response.ok) {
                        ctx.commit('deleteGroup', payload.groupId);

                        notify({
                            type: 'success',
                            text: 'Группа успешно удалена!',
                            speed: 1000,
                            duration: 5000
                        });
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async fetchStoryInActualGroup(ctx, payload) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/stories/actual/${payload}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`
                    }
                }
            )
                .then((response) => {
                    if (response.ok) {
                        response.json().then((response) => {
                            ctx.commit('updateActualStoriesInGroup', response.data.stories);
                        })
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },
    }
}