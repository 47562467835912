import {serverError, serverFailedAccess} from "@/main";
import {API_URL} from "@/core/constants";

export default {
    state: {
        fieldsOfActivity: [
            {
                name: 'Сначала новые',
                value: ''
            },
            {
                name: 'Сначала старые',
                value: ''
            },
            {
                name: 'Самые просматриваемые',
                value: ''
            },
            {
                name: 'Больше всего отметок \'нравится\'',
                value: ''
            },
        ]
    },
    getters: {
        getFieldsOfActivity(state) {
            return state.fieldsOfActivity;
        }
    },
    mutations: {
        updateDesc(state, desc) {
            state.fieldsOfActivity[0].value = desc
        },
        updateAsc(state, asc) {
            state.fieldsOfActivity[1].value = asc
        },
        updateLikes(state, likes) {
            state.fieldsOfActivity[3].value = likes
        },
        updateViews(state, views) {
            state.fieldsOfActivity[2].value = views
        },
    },
    actions: {
        async fetchFieldsOfActivity(ctx) {
            await fetch(
                `${API_URL}managers/channels/posts/sort-types`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
                    }
                }
            )
                .then((response) => {
                    if (response.ok) {
                        response.json().then((response) => {
                            const value = response.data
                            ctx.commit('updateDesc', value[0])
                            ctx.commit('updateAsc', value[1])
                            ctx.commit('updateLikes', value[2])
                            ctx.commit('updateViews', value[3])
                        })
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        }
    }
}