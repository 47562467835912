import {serverError, serverFailedAccess} from "@/main";
import {API_URL} from "@/core/constants";

export default {
  state: {
    insideUsers: [],
    usersTotalCount: null,
    usersPage: 1,
    usersMaxPage: 0,
    insideMessagesInRoom: [],
    shortList: [],
    shortListTotalCount: null,
    shortListPage: 1,
    shortListMaxPage: 0,
    insideMessageCurrentPage: 1,
    insideMessageMaxPage: 0,
    loadedPrivate: false,
    assistantMessages: [],
    assistantMessagesMaxPage: 0,
    assistantMessagesCurrentPage: 1,
  },
  getters: {
    getLoadedPrivate(state) {
      return state.loadedPrivate;
    },
    getInsideUsers(state) {
      return state.insideUsers;
    },
    getInsideShortList(state) {
      return state.shortList;
    },
    getInsideMessagesInRoom(state) {
      return state.insideMessagesInRoom;
    },
    getInsideDepartmentUserById: (state) => (userId) => {
      let department = state.insideUsers.find(user => user?.id?.toString() === userId?.toString());

      if (department === undefined) {
        department = state.insideUsers.find(user => user?.recipient?.id?.toString() === userId?.toString());
      }

      return department;
    },

    getAssistantMessages(state) {
      return state.assistantMessages;
    },
  },
  mutations: {
    updateInsideUsers(state, users) {
      state.insideUsers = users;
    },
    addInsideUsers(state, users) {
      users.forEach((user) => {
        state.insideUsers.push(user);
      });
    },
    updateUsersTotalCount(state, count) {
      state.usersTotalCount = count;
    },
    updateUserById(state, data) {
      const index = state.insideUsers.findIndex(user => user.id == data.id);

      if (index != -1) {
        let users = state.insideUsers;
        users = users.filter((user, userIndex) => userIndex !== index);
        users.unshift(state.insideUsers[index]);
        users[0].unread_messages_count++;
        users[0].last_message = data.last_message;

        state.insideUsers = users;
      } else {
        state.insideUsers.unshift(data);
      }
    },
    setUsersMaxPage(state, page) {
      state.usersMaxPage = page;
    },
    setUsersPage(state, page) {
      state.usersPage = page
    },
    updateInsideMessagesInRoom(state, messages) {
      state.insideMessagesInRoom = messages;
    },
    updateInsideMessagesCurrentPage(state, page) {
      state.insideMessageCurrentPage = page;
    },
    updateInsideMessagesMaxPage(state, page) {
      state.insideMessageMaxPage = page;
    },
    updateLastInsideMessage(state, data) {
      state.insideUsers.map((user, index) => {
        if (user.id === parseInt(data.roomId)) {
          if (user.room) {
            if (user.room.last_message) {
              user.room.last_message.message = data.message;
              user.room.last_message.created = data.created;
            } else {
              user.room.last_message = {
                message: data.message,
                created: data.created
              }
            }
          }

          state.insideUsers.unshift(...state.insideUsers.splice(index, 1));
        }
      });
    },
    deleteLastMessage(state, userId) {
      state.insideUsers.map(user => {
        if (user?.id === parseInt(userId)) {
          if (user.room) {
            user.room.last_message = null;
          }
        }
      });
    },
    deleteInsideMessageInRoom(state, messageId) {
      state.insideMessagesInRoom = state.insideMessagesInRoom.filter(message => message.id.toString() !== messageId.toString());
    },
    updatePrivateSeenMessages(state) {
      state.insideMessagesInRoom.map(message => {
        if (!message?.is_seen) {
          message.is_seen = true;
        }
      });
    },
    changeStateUnreadMessage(state, userId) {
      state.insideUsers.map(user => {
        if (user?.id === parseInt(userId)) {
          if (user) {
            user.unread_messages_count = 0;
          }
        }
      });
    },
    updateRoomForUser(state, data) {
      state.insideUsers.map(user => {
        if (user?.id === parseInt(data.roomId)) {
          user.room = data.data.room;
        }
      });
    },

    updateShortList(state, shortList) {
      state.shortList = shortList;
    },
    updateShortListTotalCount(state, count) {
      state.shortListTotalCount = count;
    },
    setShortListMaxPage(state, page) {
      state.shortListMaxPage = page;
    },
    setShortListPage(state, page) {
      state.shortListPage = page
    },
    updateRoomData(state, data) {
      const indexRoom = state.insideUsers.findIndex((user) => user.id === data.id);

      if (indexRoom !== -1) {
        state.insideUsers[indexRoom] = data;
        console.log(state.insideUsers[indexRoom])
      }
    },

    updateLoadedPrivate(state, loaded) {
      state.loadedPrivate = loaded;
    },

    updateAssistantMessages(state, messages) {
      state.assistantMessages = messages;
    },
    updateAssistantMessagesMaxPage(state, page) {
      state.assistantMessagesMaxPage = page;
    },
    updateAssistantMessagesCurrentPage(state, page) {
      state.assistantMessagesCurrentPage = page;
    },
  },
  actions: {
    async fetchShortList(ctx) {
      ctx.commit('updateLoadedPrivate', true);
      ctx.commit('updateShortList', []);

      await fetch(
        `${API_URL}managers/short-list`,
        {
          method: 'GET',
          headers: {
            Authorization: `${localStorage.token_type} ${localStorage.access_token}`
          }
        }
      )
        .then((response) => {
          if (response.ok) {
            response.json().then((response) => {
              ctx.commit('updateShortList', response.data.items);
              ctx.commit('updateShortListTotalCount', response.data.total);
              ctx.commit('setShortListMaxPage', response.data.last_page);
              ctx.commit('setShortListPage', 1);

              setTimeout(() => {
                ctx.commit('updateLoadedPrivate', false);
              }, 500);
            })
          } else {
            serverError(response);
          }
        })
        .catch(() => {
          serverFailedAccess();
        });
    },
    async fetchMoreShortList(ctx, payload) {
      ctx.commit('updateLoadedPrivate', true);
      ctx.commit('setShortListPage', ctx.state.shortListPage + 1);

      if (ctx.state.shortListPage <= ctx.state.shortListMaxPage) {
        await fetch(
          `${API_URL}managers/short-list?page=${ctx.state.shortListPage}&search=${payload}`,
          {
            method: 'GET',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`
            }
          }
        )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('updateShortList', [...ctx.state.shortList, ...response.data.items]);
                ctx.commit('updateShortListTotalCount', response.data.total);

                setTimeout(() => {
                  ctx.commit('updateLoadedPrivate', false);
                }, 500);
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
      } else {
        ctx.commit('updateLoadedPrivate', false);
      }
    },
    async searchInsideShortList(ctx, payload) {
      if (payload) {
        ctx.commit('updateLoadedPrivate', true);
        ctx.commit('updateShortList', []);

        await fetch(
          `${API_URL}managers/short-list?search=${payload}`,
          {
            method: 'GET',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`
            }
          }
        )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('updateShortList', response.data.items);
                ctx.commit('updateShortListTotalCount', response.data.total);
                ctx.commit('setShortListMaxPage', response.data.last_page);
                ctx.commit('setShortListPage', 1);

                setTimeout(() => {
                  ctx.commit('updateLoadedPrivate', false);
                }, 500);
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
      } else {
        ctx.dispatch('fetchShortList');
      }
    },

    async fetchShortListByRoomId(ctx, payload) {
      ctx.commit('updateLoadedPrivate', true);
      ctx.commit('updateShortList', []);

      await fetch(
        `${API_URL}managers/short-list?room_id=${payload}`,
        {
          method: 'GET',
          headers: {
            Authorization: `${localStorage.token_type} ${localStorage.access_token}`
          }
        }
      )
        .then((response) => {
          if (response.ok) {
            response.json().then((response) => {
              ctx.commit('updateShortList', response.data.items);
              ctx.commit('updateShortListTotalCount', response.data.total);
              ctx.commit('setShortListMaxPage', response.data.last_page);
              ctx.commit('setShortListPage', 1);

              setTimeout(() => {
                ctx.commit('updateLoadedPrivate', false);
              }, 500);
            })
          } else {
            serverError(response);
          }
        })
        .catch(() => {
          serverFailedAccess();
        });
    },
    async fetchMoreShortListByRoomId(ctx, payload) {
      ctx.commit('updateLoadedPrivate', true);
      ctx.commit('setShortListPage', ctx.state.shortListPage + 1);

      if (ctx.state.shortListPage <= ctx.state.shortListMaxPage) {
        await fetch(
          `${API_URL}managers/short-list?room_id=${payload.roomId}&page=${ctx.state.shortListPage}&search=${payload.search}`,
          {
            method: 'GET',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`
            }
          }
        )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('updateShortList', [...ctx.state.shortList, ...response.data.items]);
                ctx.commit('updateShortListTotalCount', response.data.total);

                setTimeout(() => {
                  ctx.commit('updateLoadedPrivate', false);
                }, 500);
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
      } else {
        ctx.commit('updateLoadedPrivate', false);
      }
    },
    async searchInsideShortListById(ctx, payload) {
      if (payload) {
        ctx.commit('updateLoadedPrivate', true);
        ctx.commit('updateShortList', []);

        await fetch(
          `${API_URL}managers/short-list?search=${payload.search}&room_id=${payload.roomId}`,
          {
            method: 'GET',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`
            }
          }
        )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('updateShortList', response.data.items);
                ctx.commit('updateShortListTotalCount', response.data.total);
                ctx.commit('setShortListMaxPage', response.data.last_page);
                ctx.commit('setShortListPage', 1);

                setTimeout(() => {
                  ctx.commit('updateLoadedPrivate', false);
                }, 500);
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
      } else {
        ctx.dispatch('fetchShortList');
      }
    },
    
    async fetchInsideUsers(ctx) {
      ctx.commit('updateLoadedPrivate', true);
      ctx.commit('updateInsideUsers', []);

      await fetch(
        `${API_URL}managers/private-rooms/by-rooms`,
        {
          method: 'GET',
          headers: {
            Authorization: `${localStorage.token_type} ${localStorage.access_token}`
          }
        }
      )
        .then((response) => {
          if (response.ok) {
            response.json().then((response) => {
              ctx.commit('updateInsideUsers', response.items);
              ctx.commit('updateUsersTotalCount', response.total);
              ctx.commit('setUsersMaxPage', response.last_page);
              ctx.commit('setUsersPage', 1);

              setTimeout(() => {
                ctx.commit('updateLoadedPrivate', false);
              }, 500);
            })
          } else {
            serverError(response);
          }
        })
        .catch(() => {
          serverFailedAccess();
        });
    },
    async fetchMoreInsideUsers(ctx, payload) {
      ctx.commit('updateLoadedPrivate', true);
      ctx.commit('setUsersPage', ctx.state.usersPage + 1);
      if (ctx.state.usersPage <= ctx.state.usersMaxPage) {
        await fetch(
          `${API_URL}managers/private-rooms/by-rooms?page=${ctx.state.usersPage}&search=${payload}`,
          {
            method: 'GET',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`
            }
          }
        )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('addInsideUsers', response.items);
                ctx.commit('updateUsersTotalCount', response.total);

                setTimeout(() => {
                  ctx.commit('updateLoadedPrivate', false);
                }, 500);
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
      } else {
        ctx.commit('updateLoadedPrivate', false);

        if (!ctx.state.shortList.length) {
          ctx.commit('updateLoadedPrivate', true);
        }
      }
    },
    async searchInsideUsers(ctx, payload) {
      if (payload) {
        ctx.commit('updateLoadedPrivate', true);
        ctx.commit('updateInsideUsers', []);

        await fetch(
          `${API_URL}managers/private-rooms/by-rooms?search=${payload}`,
          {
            method: 'GET',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`
            }
          }
        )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('updateInsideUsers', response.items);
                ctx.commit('updateUsersTotalCount', response.total);
                ctx.commit('setUsersMaxPage', response.last_page);
                ctx.commit('setUsersPage', 1);

                setTimeout(() => {
                  ctx.commit('updateLoadedPrivate', false);
                }, 500);
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
      } else {
        ctx.dispatch('fetchInsideUsers');
      }
    },

    async fetchInsideMessageInRoomByRecipient(ctx, payload) {
      await fetch(
        `${API_URL}managers/private-rooms/${payload}`,
        {
          method: 'GET',
          headers: {
            Authorization: `${localStorage.token_type} ${localStorage.access_token}`
          }
        }
      )
        .then((response) => {
          if (response.ok) {
            response.json().then((response) => {
              ctx.commit('updateInsideMessagesInRoom', response.data);
            })
          } else {
            serverError(response);
          }
        })
        .catch(() => {
          serverFailedAccess();
        });
    },

    async fetchInsideMessageInRoom(ctx, payload) {
      ctx.commit('updateInsideMessagesInRoom', []);

      await fetch(
        `${API_URL}managers/private-rooms/by-rooms/${payload}`,
        {
          method: 'GET',
          headers: {
            Authorization: `${localStorage.token_type} ${localStorage.access_token}`
          }
        }
      )
        .then((response) => {
          if (response.ok) {
            response.json().then((response) => {
              ctx.commit('updateInsideMessagesInRoom', response.data.items.reverse());
              ctx.commit('updateInsideMessagesCurrentPage', response.data.current_page);
              ctx.commit('updateInsideMessagesMaxPage', response.data.last_page);
            })
          } else {
            serverError(response);
          }
        })
        .catch(() => {
          serverFailedAccess();
        });
    },
    async fetchMoreInsideMessageInRoom(ctx, payload) {
      ctx.commit('updateInsideMessagesCurrentPage', ctx.state.insideMessageCurrentPage + 1);

      if (ctx.state.insideMessageCurrentPage <= ctx.state.insideMessageMaxPage) {
        await fetch(
            `${API_URL}managers/private-rooms/by-rooms/${payload}?page=${ctx.state.insideMessageCurrentPage}`,
            {
              method: 'GET',
              headers: {
                Authorization: `${localStorage.token_type} ${localStorage.access_token}`
              }
            }
        )
            .then((response) => {
              if (response.ok) {
                response.json().then((response) => {
                  ctx.commit('updateInsideMessagesInRoom', [...response.data.items.reverse(), ...ctx.state.insideMessagesInRoom]);
                })
              } else {
                serverError(response);
              }
            })
            .catch(() => {
              serverFailedAccess();
            });
      }
    },
    async sendInsideMessageInRoom(ctx, payload) {
      await fetch(
        `${API_URL}managers/private-rooms/by-rooms/${payload.roomId}`,
        {
          method: 'POST',
          headers: {
            Authorization: `${localStorage.token_type} ${localStorage.access_token}`
          },
          body: payload.data
        }
      )
        .then((response) => {
          if (response.ok) {
            response.json().then((response) => {
              ctx.commit('updateInsideMessagesInRoom', [...ctx.state.insideMessagesInRoom, ...[response.data]]);
              ctx.commit('updateRoomForUser', {
                data: response.data,
                roomId: payload.roomId,
              });
              ctx.commit('updateLastInsideMessage', {
                message: payload.data.getAll('message')[0],
                roomId: payload.roomId,
                created: new Date()
              });
            })
          } else {
            serverError(response);
          }
        })
        .catch(() => {
          serverFailedAccess();
        });
    },
    async sendInsideMessageInRoomByManagerId(ctx, payload) {
      await fetch(
        `${API_URL}managers/private-rooms/${payload.roomId}`,
        {
          method: 'POST',
          headers: {
            Authorization: `${localStorage.token_type} ${localStorage.access_token}`
          },
          body: payload.data
        }
      )
        .then((response) => {
          if (response.ok) {
            response.json().then((response) => {
              ctx.commit('updateInsideMessagesInRoom', [...ctx.state.insideMessagesInRoom, ...[response.data]]);
              ctx.commit('updateRoomForUser', {
                data: response.data,
                roomId: payload.roomId,
              });
              ctx.commit('updateLastInsideMessage', {
                message: payload.data.getAll('message')[0],
                roomId: payload.roomId,
                created: new Date()
              });
            })
          } else {
            serverError(response);
          }
        })
        .catch(() => {
          serverFailedAccess();
        });
    },
    async createGroupWithMessageInRoom(ctx, payload) {
      await fetch(
        `${API_URL}managers/private-rooms/store-multiple`,
        {
          method: 'POST',
          headers: {
            Authorization: `${localStorage.token_type} ${localStorage.access_token}`
          },
          body: payload
        }
      )
        .then((response) => {
          if (response.ok) {
            ctx.dispatch('fetchInsideUsers');
          } else {
            serverError(response);
          }
        })
        .catch(() => {
          serverFailedAccess();
        });
    },
    
    async deleteInsideMessage(ctx, payload) {
      await fetch(
        `${API_URL}managers/private-rooms/${payload.roomId}/${payload.messageId}`,
        {
          method: 'POST',
          headers: {
            Authorization: `${localStorage.token_type} ${localStorage.access_token}`
          },
          body: payload.data
        }
      )
        .then((response) => {
          if (response.ok) {
            ctx.commit('deleteInsideMessageInRoom', payload.messageId);

            setTimeout(() => {
              const lastMessage = ctx.state.insideMessagesInRoom[ctx.state.insideMessagesInRoom?.length - 1];

              if (lastMessage) {
                ctx.commit('updateLastInsideMessage', {
                  message: lastMessage.message,
                  roomId: payload.userId,
                  created: lastMessage.created
                });
              } else {
                ctx.commit('deleteLastMessage', payload.userId);
              }
            }, 100);
          } else {
            serverError(response);
          }
        })
        .catch(() => {
          serverFailedAccess();
        });
    },

    async inviteUserInGroupRoom(ctx, payload) {
      await fetch(
        `${API_URL}managers/private-rooms/by-rooms/${payload.roomId}/invite`,
        {
          method: 'POST',
          headers: {
            Authorization: `${localStorage.token_type} ${localStorage.access_token}`
          },
          body: payload.data
        }
      )
        .then((response) => {
          if (response.ok) {
            ctx.dispatch('fetchInsideUsers');
          } else {
            serverError(response);
          }
        })
        .catch(() => {
          // serverFailedAccess();
        });
    },
    async removeUserInGroupRoom(ctx, payload) {
      await fetch(
        `${API_URL}managers/private-rooms/by-rooms/${payload.roomId}/delete/${payload.userId}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `${localStorage.token_type} ${localStorage.access_token}`
          },
        }
      )
        .then((response) => {
          if (response.ok) {
            ctx.dispatch('fetchInsideUsers');
          } else {
            serverError(response);
          }
        })
        .catch(() => {
          // serverFailedAccess();
        });
    },
    async reNameGroupRoom(ctx, payload) {
      await fetch(
        `${API_URL}managers/private-rooms/by-rooms/${payload.roomId}/change-name`,
        {
          method: 'POST',
          headers: {
            Authorization: `${localStorage.token_type} ${localStorage.access_token}`
          },
          body: payload.data
        }
      )
        .then((response) => {
          if (response.ok) {
            ctx.dispatch('fetchInsideUsers');
          } else {
            serverError(response);
          }
        })
        .catch(() => {
          serverFailedAccess();
        });
    },
    async removeGroupRoom(ctx, payload) {
      await fetch(
        `${API_URL}managers/private-rooms/by-rooms/${payload.roomId}/delete`,
        {
          method: 'POST',
          headers: {
            Authorization: `${localStorage.token_type} ${localStorage.access_token}`
          },
          body: payload.data
        }
      )
        .then((response) => {
          if (response.ok) {
            ctx.dispatch('fetchInsideUsers');
          } else {
            serverError(response);
          }
        })
        .catch(() => {
          serverFailedAccess();
        });
    },

    async fetchAssistantMessages(ctx) {
      await fetch(
        `${API_URL}managers/assistant`,
        {
          method: 'GET',
          headers: {
            Authorization: `${localStorage.token_type} ${localStorage.access_token}`
          }
        }
      )
        .then((response) => {
          if (response.ok) {
            response.json().then((response) => {
              ctx.commit('updateAssistantMessages', response.data.items.reverse());
              ctx.commit('updateAssistantMessagesMaxPage', response.data.last_page);
              ctx.commit('updateAssistantMessagesCurrentPage', response.data.current_page);
            })
          } else {
            serverError(response);
          }
        })
        .catch(() => {
          serverFailedAccess();
        });
    },
    async fetchMoreAssistantMessages(ctx) {
      ctx.commit('updateAssistantMessagesCurrentPage', ctx.state.assistantMessagesCurrentPage + 1);

      if (ctx.state.assistantMessagesCurrentPage <= ctx.state.assistantMessagesMaxPage) {
        await fetch(
            `${API_URL}managers/assistant?page=${ctx.state.assistantMessagesCurrentPage}`,
            {
              method: 'GET',
              headers: {
                Authorization: `${localStorage.token_type} ${localStorage.access_token}`
              }
            }
        )
            .then((response) => {
              if (response.ok) {
                response.json().then((response) => {
                  ctx.commit('updateAssistantMessages', [...response.data.items.reverse(), ...ctx.state.assistantMessages]);
                })
              } else {
                serverError(response);
              }
            })
            .catch(() => {
              serverFailedAccess();
            });
      }
    },
    async sendAssistantMessage(ctx, payload) {
      await fetch(
        `${API_URL}managers/assistant`,
        {
          method: 'POST',
          headers: {
            Authorization: `${localStorage.token_type} ${localStorage.access_token}`
          },
          body: payload
        }
      )
        .then((response) => {
          if (response.ok) {
            response.json().then((response) => {
              ctx.commit('updateAssistantMessages', [...ctx.state.assistantMessages, ...[response.data]]);
            })
          } else {
            serverError(response);
          }
        })
        .catch(() => {
          serverFailedAccess();
        });
    },
    async resetAssistantMessage(ctx) {
      await fetch(
        `${API_URL}managers/assistant/new-dialog`,
        {
          method: 'POST',
          headers: {
            Authorization: `${localStorage.token_type} ${localStorage.access_token}`
          },
        }
      )
        .then((response) => {
          if (response.ok) {
            response.json().then(() => {
              ctx.commit('updateAssistantMessages', []);
            })
          } else {
            serverError(response);
          }
        })
        .catch(() => {
          serverFailedAccess();
        });
    },
  }
}