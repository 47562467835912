import {serverError, serverFailedAccess} from "@/main";
import {notify} from "@kyvg/vue3-notification";
import {API_URL} from "@/core/constants";

export default {
    state: {
        singlePost: [],
        subscribedPosts: [],
        subscribedPostsTotalCount: null,
        subscribedPostsMaxPage: 0,
        subscribedPostsPage: 1,
        subscribedHistories: [],
        subscribedPostsCurrentPage: 0,
        subscribedPostsLastPage: 0,

        events: [],
        eventsMaxPage: 0,
        eventsPage: 1,
    },
    getters: {
        getSinglePostData(state) {
            return state.singlePost;
        },
        getSubscribedPostCurrentPage(state) {
            return state.subscribedPostsCurrentPage;
        },
        getSubscribedPostLastPage(state) {
            return state.subscribedPostsLastPage;
        },
        getSubscribedPosts(state) {
            return state.subscribedPosts;
        },
        getSubscribedPostsTotalCount(state) {
            return state.subscribedPostsTotalCount;
        },
        getSubscribedHistories(state) {
            let subscribedHistories = [...state.subscribedHistories];

            subscribedHistories.map(story => {
                story.stories.sort((a, b) => {
                    return a.id > b.id ? -1 : 1;
                });
            });

            return subscribedHistories;
        },

        getEventsFeed(state) {
            return state.events;
        },
        getEventsFeedCurrentPage(state) {
            return state.eventsPage;
        },
    },
    mutations: {
        updateSinglePost(state, singlePost) {
            state.singlePost = singlePost;
        },
        updateSubscribedPosts(state, subscribedPosts) {
            state.subscribedPosts = subscribedPosts;
        },
        updateSubscribedPostsTotalCount(state, count) {
            state.subscribedPostsTotalCount = count;
        },
        updateSubscribedPostsPage(state, data) {
            state.subscribedPostsCurrentPage = data.current_page;
            state.subscribedPostsLastPage = data.last_page;
        },
        deleteSubscribedPost(state, postId) {
            state.subscribedPosts.map((post, index) => {
                if (post.id === parseInt(postId)) {
                    state.subscribedPosts.splice(index, 1)
                }
            });
        },
        setSubscribedPostMaxPage(state, page) {
            state.subscribedPostsMaxPage = page;
        },
        setSubscribedPostPage(state, page) {
            state.subscribedPostsPage = page;
        },
        updateSubscribedHistories(state, histories) {
            histories.map(story => {
                let viewed = true;

                story.stories.map(storyItem => {
                    if (storyItem.videos.length) {
                        if (!storyItem.videos[0].viewed) {
                            viewed = false;
                        }
                    } else {
                        if (!storyItem.viewed) {
                            viewed = false;
                        }
                    }
                });

                story.viewed = viewed;
            });

            histories.sort((a, b) => {
                return (a.viewed === b.viewed)? 0 : a.viewed? 1 : -1;
            });

            state.subscribedHistories = histories;
        },
        changeLikeForPost(state, postId) {
            state.subscribedPosts.map(post => {
                if (post.id === parseInt(postId)) {
                    post.liked = !post.liked;

                    if (post.liked) {
                        post.likes += 1;
                    } else {
                        post.likes -= 1;
                    }
                }
            });
        },
        changeRepostForPost(state, postId) {
            state.subscribedPosts.map(post => {
                if (post.id === parseInt(postId)) {
                    post.shares += 1;
                }
            });
        },
        changeQuizVote(state, data) {
            state.subscribedPosts.map(post => {
                if (post.id === parseInt(data.postId)) {
                    if (post.questions[0].id === parseInt(data.quizId)) {
                        post.questions[0].answers.map(answer => {
                            if (answer.id === parseInt(data.answerRealId)) {
                                answer.voted = !answer.voted;

                                if (answer.voted) {
                                    answer.votes += 1;
                                } else {
                                    answer.votes -= 1;
                                }
                            }
                        });

                        post.questions[0].votes_count += 1;
                    }
                }
            });
        },
        addComments(state, data) {
            state.subscribedPosts.map(post => {
                if (post.id === parseInt(data.postId)) {
                    post.comments = data.comments;
                    post.comments_count = data.comments.length;
                }
            });
        },
        markViewed(state, data) {
            if (data.videoId !== undefined) {
                state.subscribedHistories.map(story => {
                    story.stories.map(storyItem => {
                        if (storyItem.id === parseInt(data.storyId)) {
                            if (storyItem.videos[0].id === parseInt(data.videoId)) {
                                storyItem.videos[0].viewed = true;
                            }
                        }
                    });
                });
            } else {
                state.subscribedHistories.map(story => {
                    story.stories.map(storyItem => {
                        if (storyItem.id === parseInt(data.storyId)) {
                            storyItem.viewed = true;
                        }
                    });
                });
            }
        },
        changeViewsForPost(state, data) {
            state.subscribedPosts.map(post => {
                if (post.id === parseInt(data.postId)) {
                    post.views = data.views;
                }
            });
        },

        updateEventsFeed(state, subscribedPosts) {
            state.events = subscribedPosts;
        },
        updateEventsPage(state, page) {
            state.eventsPage = page;
        },
        updateEventsMaxPage(state, page) {
            state.eventsMaxPage = page;
        },
    },
    actions: {
        async fetchSubscribedPosts(ctx) {
            await fetch(
                `${API_URL}managers/posts/subscribed`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
                    }
                }
            ).then((response) => {
                if (response.ok) {
                    response.json().then((response) => {
                        ctx.commit('updateSubscribedPosts', response.data.items);
                        ctx.commit('updateSubscribedPostsTotalCount', response.data.total);
                        ctx.commit('setSubscribedPostMaxPage', response.data.last_page);
                        ctx.commit('setSubscribedPostPage', 1);
                        ctx.commit('updateSubscribedPostsPage', response.data);
                    })
                } else {
                    serverError(response);
                }
            })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async fetchMoreSubscribedPosts(ctx) {
            ctx.commit('setSubscribedPostPage', ctx.state.subscribedPostsPage + 1);

            if (ctx.state.subscribedPostsPage <= ctx.state.subscribedPostsMaxPage) {
                await fetch(
                    `${API_URL}managers/posts/subscribed?page=${ctx.state.subscribedPostsPage}`,
                    {
                        method: 'GET',
                        headers: {
                            Authorization: `${localStorage.token_type} ${localStorage.access_token}`
                        }
                    }
                )
                    .then((response) => {
                        if (response.ok) {
                            response.json().then((response) => {
                                ctx.commit('updateSubscribedPosts', [...ctx.state.subscribedPosts, ...response.data.items]);
                                ctx.commit('updateSubscribedPostsTotalCount', response.data.total);
                                ctx.commit('updateSubscribedPostsPage', response.data);
                            })
                        } else {
                            serverError(response);
                        }
                    })
                    .catch(() => {
                        serverFailedAccess();
                    });
            }
        },
        async fetchSubscribedPostById(ctx, payload) {
            await fetch(
                `${API_URL}managers/posts/subscribed/${payload}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`
                    }
                }
            )
                .then((response) => {
                    if (response.ok) {
                        response.json().then((response) => {
                            ctx.commit('deleteSubscribedPost', payload);
                            ctx.commit('updateSubscribedPosts', [...[response.data], ...ctx.state.subscribedPosts]);
                        })
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async fetchSubscribedHistories(ctx) {
            await fetch(
                `${API_URL}managers/stories`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
                    }
                }
            ).then((response) => {
                if (response.ok) {
                    response.json().then((response) => {
                        ctx.commit('updateSubscribedHistories', response.data);
                    })
                } else {
                    serverError(response);
                }
            })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async setRepostPost(ctx, payload) {
            await fetch(
                `${API_URL}managers/posts/subscribed/${payload}/share`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
                    }
                }
            ).then((response) => {
                if (response.ok) {
                    ctx.commit('changeRepostForPost', payload);
                } else {
                    serverError(response);
                }
            })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async setLikePost(ctx, payload) {
            await fetch(
                `${API_URL}managers/posts/subscribed/${payload}/like`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
                    }
                }
            ).then((response) => {
                if (response.ok) {
                    ctx.commit('changeLikeForPost', payload);
                } else {
                    serverError(response);
                }
            })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async setUnLikePost(ctx, payload) {
            await fetch(
                `${API_URL}managers/posts/subscribed/${payload}/unlike`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
                    }
                }
            ).then((response) => {
                if (response.ok) {
                    ctx.commit('changeLikeForPost', payload);
                } else {
                    serverError(response);
                }
            })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async setVoteForQuiz(ctx, payload) {
            await fetch(
                `${API_URL}managers/posts/questions/${payload.quizId}/vote`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
                    },
                    body: payload.answerId
                }
            ).then((response) => {
                if (response.ok) {
                    ctx.commit('changeQuizVote', payload);
                } else {
                    serverError(response);
                }
            })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async getComments(ctx, payload) {
            await fetch(
                `${API_URL}managers/posts/subscribed/${payload}/comments`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
                    }
                }
            ).then((response) => {
                if (response.ok) {
                    response.json().then((response) => {
                        ctx.commit('addComments', {comments: response.data, postId: payload});
                    });
                } else {
                    serverError(response);
                }
            })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async setComments(ctx, payload) {
            await fetch(
                `${API_URL}managers/posts/subscribed/${payload.postId}/comments`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
                    },
                    body: payload.data
                }
            ).then((response) => {
                if (response.ok) {
                    this.dispatch('getComments', payload.postId);
                } else {
                    serverError(response);
                }
            })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async getPostWithoutChecks(ctx, payload) {
            await fetch(
                `${API_URL}posts/show/${payload}`,
                {
                    method: 'GET'
                }
            ).then((response) => {
                if (response.ok) {
                    response.json().then((response) => {
                        ctx.commit('updateSinglePost', [response.data]);
                    })
                } else {
                    serverError(response);
                }
            })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async repostForMyChannel(ctx, payload) {
            await fetch(
                `${API_URL}managers/posts/subscribed/${payload}/copy/${localStorage.channelId}`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
                    }
                }
            ).then((response) => {
                if (response.ok) {
                    notify({
                        type: 'success',
                        text: 'Репост успешно опубликован!',
                        speed: 1000,
                        duration: 5000
                    });

                    this.dispatch('setRepostPost', payload);
                } else {
                    serverError(response);
                }
            })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async markViewedStory(ctx, payload) {
            await fetch(
                `${API_URL}managers/stories/${payload.storyId}/mark-as-seen`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`
                    },
                    body: payload.body
                }
            )
                .then((response) => {
                    if (response.ok) {
                        ctx.commit('markViewed', payload);
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async setReadPost(ctx, payload) {
            await fetch(
                `${API_URL}managers/posts/subscribed/${payload}/mark-as-seen`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
                    }
                }
            ).then((response) => {
                if (response.ok) {
                    response.json().then((response) => {
                        ctx.commit('changeViewsForPost', {postId: payload, views: response.data.views});
                    });
                } else {
                    serverError(response);
                }
            })
                .catch(() => {
                    serverFailedAccess();
                });
        },

        async fetchEventsFeed(ctx, queryParam) {
            await fetch(
                `${API_URL}managers/events${queryParam}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
                    }
                }
            ).then((response) => {
                if (response.ok) {
                    response.json().then((response) => {
                        ctx.commit('updateEventsFeed', response.data.items);
                        ctx.commit('updateEventsMaxPage', response.data.last_page);
                        ctx.commit('updateEventsPage', 1);
                    })
                } else {
                    serverError(response);
                }
            })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async fetchMoreEventsFeed(ctx, queryParam) {
            ctx.commit('updateEventsPage', ctx.state.eventsPage + 1);

            if (ctx.state.eventsPage <= ctx.state.eventsMaxPage) {
                await fetch(
                    `${API_URL}managers/events${queryParam}`,
                    {
                        method: 'GET',
                        headers: {
                            Authorization: `${localStorage.token_type} ${localStorage.access_token}`
                        }
                    }
                )
                    .then((response) => {
                        if (response.ok) {
                            response.json().then((response) => {
                                ctx.commit('updateEventsFeed', [...ctx.state.events, ...response.data.items]);
                            })
                        } else {
                            serverError(response);
                        }
                    })
                    .catch(() => {
                        serverFailedAccess();
                    });
            }
        },
    }
}