import {serverError, serverFailedAccess} from "@/main";
import {API_URL} from "@/core/constants";

export default {
    state: {
        organization: []
    },
    getters: {
        getOrganization(state) {
            return state.organization;
        }
    },
    mutations: {
        updateOrganization(state, organization) {
            state.organization = organization;
        }
    },
    actions: {
        async apiCreateOrganization(ctx, payload) {
            await fetch(
                `${API_URL}managers/auth/register`,
                {
                    method: 'POST',
                    body: payload
                }
            )
                .then((response) => {
                    if (response.ok) {
                        //
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        }
    }
}