import {notify} from "@kyvg/vue3-notification";
import {serverError, serverFailedAccess} from "@/main";
import {API_URL} from "@/core/constants";

export default {
    state: {
        types: [],
        documentHeader: {
            organization_name: '',
            organization_national_name: '',
            address: '',
            phone: '',
            email: '',
            emblem: '',
            director: '',
            position: '',
            type: {
                name: ''
            }
        },
        documentCommonInfo: {
            date: '1970-12-04',
            number: 'Номер документа',
            type_id: '',
            type_name: '',
            title: 'Заголовок документа (о чем НПА)',
        },
        currentDocInfo: {},
        userListForSend: [],
        isDocumentSaved: false
    },
    getters: {
        getTypesDocument(state) {
            return state.types;
        },
        getTypesDocumentForNpa(state) {
            const typesDoc = [];

            typesDoc.push({id: '', name: 'Все документы'});

            state.types.forEach((type) => {
                typesDoc.push(type);
            });

            return typesDoc;
        },
        getDocumentHeader(state) {
            return state.documentHeader;
        },
        getDocumentCommonInfo(state) {
            return state.documentCommonInfo;
        },
        getCurrentDocumentId(state) {
            return state.currentDocInfo.id;
        },
        getCurrentDocumentPdfUrl(state) {
            return state.currentDocInfo.document;
        },
        getUserListForSend(state) {
            return state.userListForSend;
        },
        getIsDocumentSaved(state) {
            return state.isDocumentSaved;
        }
    },
    mutations: {
        updateTypes(state, types) {
            state.types = types;
        },
        updateCurrentDocInfo(state, currentDocInfo) {
            state.currentDocInfo = currentDocInfo;
        },
        updateDocumentHeader(state, documentHeader) {
            state.documentHeader = documentHeader;
        },
        updateDocumentCommonInfoDate(state, date) {
            state.documentCommonInfo.date = date;
        },
        updateDocumentCommonInfoType(state, payload) {
            state.documentCommonInfo.type_id = payload.type_id;
            state.documentCommonInfo.type_name = payload.type_name;
        },
        updateDocumentCommonInfoNumber(state, payload) {
            state.documentCommonInfo.number = payload;
        },
        updateDocumentCommonInfoTitle(state, title) {
            state.documentCommonInfo.title = title;
        },
        updateUserListForSend(state, userList) {
            state.userListForSend = userList;
        },
        switchIsDocumentSaved(state, value) {
            return state.isDocumentSaved = value;
        }
    },
    actions: {
        async createConstructorDocument(ctx, payload) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/documents`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`
                    },
                    body: payload
                }
            )
                .then((response) => {
                    if (response.ok) {
                        response.json().then((response) => {
                            ctx.commit('updateCurrentDocInfo', response.data[0]);
                            ctx.commit('switchIsDocumentSaved', true);
                        });

                        notify({
                            type: 'success',
                            text: 'Документ успешно сохранен',
                            speed: 1000,
                            duration: 5000
                        });
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async changeConstructorDocument(ctx, payload) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/documents/${payload.documentId}`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`
                    },
                    body: payload.data
                }
            )
                .then((response) => {
                    if (response.ok) {
                        response.json().then((response) => {
                            ctx.commit('updateCurrentDocInfo', response.data[0]);
                            ctx.commit('switchIsDocumentSaved', true);
                        });

                        notify({
                            type: 'success',
                            text: 'Документ успешно сохранен',
                            speed: 1000,
                            duration: 5000
                        });
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async sendConstructorDocument(ctx, payload) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/documents/${this.getters.getCurrentDocumentId}/send`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`
                    },
                    body: payload
                }
            )
                .then((response) => {
                    if (response.ok) {
                        notify({
                            type: 'success',
                            text: 'Документ успешно отправлен!',
                            speed: 1000,
                            duration: 5000
                        });
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async downloadConstructorDocument(/*ctx*/) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/documents/${this.getters.getCurrentDocumentId}/download`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`
                    }
                }
            )
                .then((response) => {
                    if (response.ok) {
                        const blob = new Blob([response], {type: 'application/pdf'});
                        const url = URL.createObjectURL(blob);
                        window.open(url);
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async fetchListUsersForSend(ctx) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/teams/allowed-users`,
                {
                    method: 'get',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`
                    }
                }
            )
                .then((response) => {
                    if (response.ok) {
                        response.json().then((response) => {
                            ctx.commit('updateUserListForSend', response.data);
                        })
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },

        async fetchTypes(ctx) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/documents/types`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`
                    }
                }
            )
                .then((response) => {
                    if (response.ok) {
                        response.json().then((response) => {
                            ctx.commit('updateTypes', response.data);
                        })
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async fetchHeaderDocument(ctx) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/documents/headers`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`
                    }
                }
            )
                .then((response) => {
                    if (response.ok) {
                        response.json().then((response) => {
                            ctx.commit('updateDocumentHeader', response.data);
                        })
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async createHeaderByType(ctx, data) {
            await fetch(
                data.documentType === ''
                    ? `${API_URL}managers/channels/${localStorage.channelId}/documents/headers`
                    : `${API_URL}managers/channels/${localStorage.channelId}/documents/headers/${data.documentType}`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`
                    },
                    body: data.data
                }
            )
                .then((response) => {
                    if (response.ok) {
                        response.json().then((response) => {
                            ctx.commit('updateDocumentHeader', response.data);
                        })
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        }
    }
}