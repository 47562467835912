export default {
    state: {
        users: []
    },
    getters: {
        getUsers(state) {
            return state.users;
        }
    },
    mutations: {
        updateUsers(state, users) {
            state.users = users;
        }
    },
    actions: {
        async fetchUsers(ctx) {
            // const response = await fetch(
            //     ''
            // );

            // const users = await response.json();
            const users = [
                {
                    img: 'comment-user',
                    name: 'Татьяна Могорчук',
                    in_team: false
                },
                {
                    img: 'comment-user',
                    name: 'Татьяна Могорчук',
                    in_team: true
                },
                {
                    img: 'comment-user',
                    name: 'Татьяна Могорчук',
                    in_team: false
                },
                {
                    img: 'comment-user',
                    name: 'Татьяна Могорчук',
                    in_team: true
                },
                {
                    img: 'comment-user',
                    name: 'Татьяна Могорчук',
                    in_team: false
                },
                {
                    img: 'comment-user',
                    name: 'Татьяна Могорчук',
                    in_team: true
                },
                {
                    img: 'comment-user',
                    name: 'Татьяна Могорчук',
                    in_team: false
                },
                {
                    img: 'comment-user',
                    name: 'Татьяна Могорчук',
                    in_team: true
                },
                {
                    img: 'comment-user',
                    name: 'Татьяна Могорчук',
                    in_team: false
                },
                {
                    img: 'comment-user',
                    name: 'Татьяна Могорчук',
                    in_team: true
                },
                {
                    img: 'comment-user',
                    name: 'Татьяна Могорчук',
                    in_team: false
                },
                {
                    img: 'comment-user',
                    name: 'Татьяна Могорчук',
                    in_team: true
                },
                {
                    img: 'comment-user',
                    name: 'Татьяна Могорчук',
                    in_team: false
                },
                {
                    img: 'comment-user',
                    name: 'Татьяна Могорчук',
                    in_team: true
                }
            ];
            ctx.commit('updateUsers', users)
        },
    }
}