import {serverError, serverFailedAccess} from "@/main";
import {API_URL} from "@/core/constants";

export default {
  state: {
    productList: [],
    productListCurrentPage: 0,
    productListMaxPage: 0,

    cabinetProductList: [],
    cabinetProductListCurrentPage: 0,
    cabinetProductListMaxPage: 0,

    productCategoryList: [],
    productAllCategoryList: [],

    orderList: [],
    orderListCurrentPage: 0,
    orderListMaxPage: 0,

    myOrderList: [],
    myOrderListCurrentPage: 0,
    myOrderListMaxPage: 0,

    offerList: [],
    offerListCurrentPage: 0,
    offerListMaxPage: 0,

    myOfferList: [],
    myOfferListCurrentPage: 0,
    myOfferListMaxPage: 0,
  },
  getters: {
    getProductList(state) {
      return state.productList;
    },
    getProductListPage(state) {
      return state.productListCurrentPage;
    },
    getProductListMaxPage(state) {
      return state.productListMaxPage;
    },

    getCabinetProductList(state) {
      return state.cabinetProductList;
    },
    getCabinetProductListPage(state) {
      return state.cabinetProductListCurrentPage;
    },
    getCabinetProductListMaxPage(state) {
      return state.cabinetProductListMaxPage;
    },

    getProductCategoryList(state) {
      return state.productCategoryList;
    },
    getProductAllCategoryList(state) {
      return state.productAllCategoryList;
    },

    getOrderList(state) {
      return state.orderList;
    },
    getOrderCurrentPage(state) {
      return state.orderListCurrentPage
    },
    getOrderMaxPage(state) {
      return state.orderListMaxPage
    },

    getMyOrderList(state) {
      return state.myOrderList;
    },
    getMyOrderCurrentPage(state) {
      return state.myOrderListCurrentPage
    },
    getMyOrderMaxPage(state) {
      return state.myOrderListMaxPage
    },

    getOfferList(state) {
      return state.offerList;
    },
    getOfferCurrentPage(state) {
      return state.offerListCurrentPage
    },
    getOfferMaxPage(state) {
      return state.offerListMaxPage
    },

    getMyOfferList(state) {
      return state.myOfferList;
    },
    getMyOfferCurrentPage(state) {
      return state.myOfferListCurrentPage
    },
    getMyOfferMaxPage(state) {
      return state.myOfferListMaxPage
    },
  },
  mutations: {
    updateProductList(state, productList) {
      state.productList = productList;
    },
    updateProductById(state, data) {
      const productIndex = state.productList.findIndex(product => product.id.toString() === data.productId.toString());

      if (productIndex !== -1) {
        state.productList[productIndex] = data.data;
      }
    },
    setPageNumberForProductList(state, page) {
      state.productListCurrentPage = page;
    },
    setMaxPageNumberForProductList(state, page) {
      state.productListMaxPage = page;
    },

    updateCabinetProductList(state, productList) {
      state.cabinetProductList = productList;
    },
    updateCabinetProductById(state, data) {
      const productIndex = state.cabinetProductList.findIndex(product => product.id.toString() === data.productId.toString());

      if (productIndex !== -1) {
        state.cabinetProductList[productIndex] = data.data;
      }
    },
    setPageNumberForCabinetProductList(state, page) {
      state.cabinetProductListCurrentPage = page;
    },
    setMaxPageNumberForCabinetProductList(state, page) {
      state.cabinetProductListMaxPage = page;
    },

    updateProductCategoryList(state, productCategoryList) {
      state.productCategoryList = productCategoryList;
    },
    updateProductAllCategoryList(state, productCategoryList) {
      state.productAllCategoryList = productCategoryList;
    },

    updateOrderList(state, orderList) {
      state.orderList = orderList;
    },
    updateOrderCurrentPage(state, page) {
      state.orderListCurrentPage = page;
    },
    updateOrderMaxPage(state, page) {
      state.orderListMaxPage = page;
    },

    updateMyOrderList(state, orderList) {
      state.myOrderList = orderList;
    },
    updateMyOrderCurrentPage(state, page) {
      state.myOrderListCurrentPage = page;
    },
    updateMyOrderMaxPage(state, page) {
      state.myOrderListMaxPage = page;
    },

    updateOfferList(state, offerList) {
      state.offerList = offerList;
    },
    updateOfferCurrentPage(state, page) {
      state.offerListCurrentPage = page;
    },
    updateOfferMaxPage(state, page) {
      state.offerListMaxPage = page;
    },

    updateMyOfferList(state, offerList) {
      state.myOfferList = offerList;
    },
    updateMyOfferCurrentPage(state, page) {
      state.myOfferListCurrentPage = page;
    },
    updateMyOfferMaxPage(state, page) {
      state.myOfferListMaxPage = page;
    },
  },
  actions: {
    async fetchProductList(ctx, payload) {
      if (ctx.state.productListCurrentPage != 0) {
        ctx.commit('setPageNumberForProductList', ctx.state.productListCurrentPage + 1);
      }

      if (ctx.state.productListCurrentPage <= ctx.state.productListMaxPage) {
        await fetch(
            // `${API_URL}managers/channels/${localStorage.channelId}/products${payload}`,
            `${API_URL}managers/products${payload}`,
            {
              method: 'GET',
              headers: {
                Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
              }
            }
        )
            .then((response) => {
              if (response.ok) {
                response.json().then((response) => {
                  if (ctx.state.productListCurrentPage == 0) {
                    ctx.commit('updateProductList', response.data.items);
                  } else {
                    ctx.commit('updateProductList', [...ctx.state.productList, ...response.data.items]);
                  }

                  if (ctx.state.productListCurrentPage == 0) {
                    ctx.commit('setMaxPageNumberForProductList', response.data.last_page);
                    ctx.commit('setPageNumberForProductList', 1);
                  }
                })
              } else {
                serverError(response);
              }
            })
            .catch(() => {
              serverFailedAccess();
            });
      }
    },
    async addNewProductForCatalog(ctx, payload) {
      await fetch(
          `${API_URL}managers/channels/${localStorage.channelId}/products`,
          {
            method: 'POST',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            },
            body: payload,
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('updateProductList', [...[response.data], ...ctx.state.productList]);
                ctx.commit('updateCabinetProductList', [...[response.data], ...ctx.state.cabinetProductList]);
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async removeProductForCatalog(ctx, payload) {
      await fetch(
          `${API_URL}managers/channels/${localStorage.channelId}/products/${payload.productId}`,
          {
            method: 'POST',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            },
            body: payload.data,
          }
      )
          .then((response) => {
            if (response.ok) {
              const products = ctx.state.productList.filter(product => product.id.toString() !== payload.productId.toString());
              ctx.commit('updateProductList', products);

              const cabinetProducts = ctx.state.cabinetProductList.filter(product => product.id.toString() !== payload.productId.toString());
              ctx.commit('updateCabinetProductList', cabinetProducts);
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async editProductForCatalog(ctx, payload) {
      await fetch(
          `${API_URL}managers/channels/${localStorage.channelId}/products/${payload.productId}`,
          {
            method: 'POST',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            },
            body: payload.data,
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('updateProductById', {data: response.data, productId: payload.productId});
                ctx.commit('updateCabinetProductById', {data: response.data, productId: payload.productId});
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },

    async fetchCabinetProductList(ctx, payload) {
      if (ctx.state.cabinetProductListCurrentPage != 0) {
        ctx.commit('setPageNumberForCabinetProductList', ctx.state.cabinetProductListCurrentPage + 1);
      }

      if (ctx.state.cabinetProductListCurrentPage <= ctx.state.cabinetProductListMaxPage) {
        await fetch(
            `${API_URL}managers/channels/${localStorage.channelId}/products${payload}`,
            {
              method: 'GET',
              headers: {
                Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
              }
            }
        )
            .then((response) => {
              if (response.ok) {
                response.json().then((response) => {
                  if (ctx.state.cabinetProductListCurrentPage == 0) {
                    ctx.commit('updateCabinetProductList', response.data.items);
                  } else {
                    ctx.commit('updateCabinetProductList', [...ctx.state.cabinetProductList, ...response.data.items]);
                  }

                  if (ctx.state.cabinetProductListCurrentPage == 0) {
                    ctx.commit('setMaxPageNumberForCabinetProductList', response.data.last_page);
                    ctx.commit('setPageNumberForCabinetProductList', 1);
                  }
                })
              } else {
                serverError(response);
              }
            })
            .catch(() => {
              serverFailedAccess();
            });
      }
    },

    async fetchProductCategoryList(ctx) {
      await fetch(
          `${API_URL}managers/products/categories/actives`,
          {
            method: 'GET',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            }
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('updateProductCategoryList', response.data);
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async fetchProductAllCategoryList(ctx) {
      await fetch(
          `${API_URL}managers/products/categories`,
          {
            method: 'GET',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            }
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('updateProductAllCategoryList', response.data);
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },

    async fetchOrderList(ctx, payload) {
      if (ctx.state.orderListCurrentPage != 0) {
        ctx.commit('updateOrderCurrentPage', ctx.state.orderListCurrentPage + 1);
      }

      await fetch(
          `${API_URL}managers/trade/orders${payload}`,
          {
            method: 'GET',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            }
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                if (response.data.items !== undefined) {
                  if (ctx.state.orderListCurrentPage == 0) {
                    ctx.commit('updateOrderList', response.data.items);
                  } else {
                    ctx.commit('updateOrderList', [...ctx.state.orderList, ...response.data.items]);
                  }

                  if (ctx.state.orderListCurrentPage == 0) {
                    ctx.commit('updateOrderMaxPage', response.data.last_page);
                    ctx.commit('updateOrderCurrentPage', 1);
                  }
                } else {
                  ctx.commit('updateOrderList', response.data);
                }
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async fetchOrder(ctx, id) {
      await fetch(
          `${API_URL}managers/trade/order/show/${id}`,
          {
            method: 'GET',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            }
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                const orderList = response.data;
                ctx.commit('updateMyOrderList', orderList);
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async createOrder(ctx, payload) {
      await fetch(
          `${API_URL}managers/channels/${localStorage.channelId}/trade/orders`,
          {
            method: 'POST',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            },
            body: payload
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then(() => {
                ctx.dispatch('fetchMyOrderList', '');
                ctx.dispatch('fetchOrderList', '');
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async updateOrder(ctx, payload) {
      await fetch(
          `${API_URL}managers/trade/order/update`,
          {
            method: 'POST',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            },
            body: payload
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then(() => {
                //const orderList = response.data;
                //ctx.commit('updateMyOrderList', orderList);
                ctx.dispatch('fetchMyOrderList', '');
                ctx.dispatch('fetchOrderList', '');
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async cancelOrder(ctx, payload) {
      await fetch(
          `${API_URL}managers/trade/order/cancel`,
          {
            method: 'POST',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            },
            body: payload
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then(() => {
                //const orderList = response.data;
                //ctx.commit('updateMyOrderList', orderList);
                ctx.dispatch('fetchMyOrderList', '');
                ctx.dispatch('fetchOrderList', '');
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async closeOrder(ctx, payload) {
      await fetch(
          `${API_URL}managers/trade/order/close/`,
          {
            method: 'POST',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            },
            body: payload
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then(() => {
                //const orderList = response.data;
                //ctx.commit('updateMyOrderList', orderList);
                ctx.dispatch('fetchMyOrderList', '');
                ctx.dispatch('fetchOrderList', '');
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },

    async sendEmail(ctx, payload) {
      await fetch(
          `${API_URL}managers/channels/${payload.id}/send-email`,
          {
            method: 'POST',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            },
            body: payload.payload
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then(() => {
                //const orderList = response.data;
                //ctx.commit('updateMyOrderList', orderList);
                ctx.dispatch('fetchMyOrderList', '');
                ctx.dispatch('fetchOrderList', '');
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },

    async fetchMyOrderList(ctx, payload) {
      if (ctx.state.myOrderListCurrentPage != 0) {
        ctx.commit('updateMyOrderCurrentPage', ctx.state.myOrderListCurrentPage + 1);
      }

      await fetch(
          `${API_URL}managers/trade/my-orders${payload}`,
          {
            method: 'GET',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            }
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                if (response.data.items !== undefined) {
                  if (ctx.state.myOrderListCurrentPage == 0) {
                    ctx.commit('updateMyOrderList', response.data.items);
                  } else {
                    ctx.commit('updateMyOrderList', [...ctx.state.myOrderList, ...response.data.items]);
                  }

                  if (ctx.state.myOrderListCurrentPage == 0) {
                    ctx.commit('updateMyOrderMaxPage', response.data.last_page);
                    ctx.commit('updateMyOrderCurrentPage', 1);
                  }
                } else {
                  ctx.commit('updateMyOrderList', response.data);
                }
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },

    async fetchOfferList(ctx, query) {
      if (ctx.state.offerListCurrentPage != 0) {
        ctx.commit('updateOfferCurrentPage', ctx.state.offerListCurrentPage + 1);
      }

      if (ctx.state.offerListCurrentPage <= ctx.state.offerListMaxPage) {
        await fetch(
            `${API_URL}managers/channels/${localStorage.channelId}/trade/offers${query}`,
            // `${API_URL}managers/trade/offers`,
            {
              method: 'GET',
              headers: {
                Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
              }
            }
        )
            .then((response) => {
              if (response.ok) {
                response.json().then((response) => {
                  if (ctx.state.offerListCurrentPage == 0) {
                    ctx.commit('updateOfferList', response.data.items);
                  } else {
                    ctx.commit('updateOfferList', [...ctx.state.offerList, ...response.data.items]);
                  }

                  if (ctx.state.offerListCurrentPage == 0) {
                    ctx.commit('updateOfferMaxPage', response.data.last_page);
                    ctx.commit('updateOfferCurrentPage', 1);
                  }
                })
              } else {
                serverError(response);
              }
            })
            .catch(() => {
              serverFailedAccess();
            });
      }
    },
    async createOffer(ctx, data) {
      await fetch(
          `${API_URL}managers/channels/${localStorage.channelId}/trade/offers/${data.orderId}`,
          {
            method: 'POST',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            },
            body: data.payload
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then(() => {
                ctx.dispatch('fetchMyOfferList', '');
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async updateOffer(ctx, payload) {
      await fetch(
          `${API_URL}managers/trade/offer/update/`,
          {
            method: 'POST',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            },
            body: payload
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then(() => {
                ctx.dispatch('fetchMyOfferList', '');
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async revokeOffer(ctx, payload) {
      await fetch(
          `${API_URL}managers/trade/offer/revoke/`,
          {
            method: 'POST',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            },
            body: payload
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then(() => {
                ctx.dispatch('fetchMyOfferList', '');
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },

    async fetchMyOfferList(ctx, payload) {
      if (ctx.state.myOfferListCurrentPage != 0) {
        ctx.commit('updateMyOfferCurrentPage', ctx.state.myOfferListCurrentPage + 1);
      }

      await fetch(
          `${API_URL}managers/trade/my-offers${payload}`,
          {
            method: 'GET',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            }
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                if (response.data.items !== undefined) {
                  if (ctx.state.myOfferListCurrentPage == 0) {
                    ctx.commit('updateMyOfferList', response.data.items);
                  } else {
                    ctx.commit('updateMyOfferList', [...ctx.state.myOfferList, ...response.data.items]);
                  }

                  if (ctx.state.myOfferListCurrentPage == 0) {
                    ctx.commit('updateMyOfferMaxPage', response.data.last_page);
                    ctx.commit('updateMyOfferCurrentPage', 1);
                  }
                } else {
                  ctx.commit('updateMyOfferList', response.data);
                }
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
  }
}