<script>
import "@/views/Landing/style/variables.css";
import CommonContent from "@/views/Landing/content/common-content.vue";
import BusinessContent from "@/views/Landing/content/business-content.vue";
import DepartmentContent from "@/views/Landing/content/department-content.vue";
import TradingContent from "@/views/Landing/content/trading-content.vue";
import {mapActions} from "vuex";

export default {
  data: () => ({
    state: 'common',
    showScrollTopBtn: false,
  }),
  name: "common-page",
  components: {
    CommonContent,
    BusinessContent,
    DepartmentContent,
    TradingContent,
  },
  beforeMount() {
    if (localStorage.access_token) {
      this.$router.push('/channels');
    }
  },
  mounted() {
    const headerHeight = document.querySelector('.landing header')?.offsetHeight;
    document.documentElement.style.setProperty('--landing-header-height', headerHeight + 'px');

    document.querySelector('.landing main').addEventListener('scroll', (event) => {
      if ((event.target.scrollTop > 500 && !this.showScrollTopBtn) ||
          (event.target.scrollTop < 500 && this.showScrollTopBtn)) {
        this.switchShowScrollTopBtn();
      }
    });
  },
  computed: {
    changeState() {
      return (newState) => {
        document.querySelector('.landing main').scrollTo({
          top: 0,
        });

        setTimeout(() => {
          this.state = newState;
        }, 100);
      }
    },
    switchShowScrollTopBtn() {
      return () => {
        this.showScrollTopBtn = !this.showScrollTopBtn;
      }
    },
  },
  methods: {
    ...mapActions([
      'landingCallback',
    ]),
    sendQuestion(data) {
      const payload = new FormData();
      const phoneToNumber = Number(
          data.phone
              .replace(/-/g, '')
              .replace(/ /g, '')
              .replace('(', '')
              .replace(')', '')
              .replace('+7', '8')
      );

      payload.append('name', data.name);
      payload.append('organization', data.organization || '');
      payload.append('phone', phoneToNumber);

      this.landingCallback(payload);
    },
    scrollToTop() {
      document.querySelector('.landing main').scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    scrollToBottom() {
      document.querySelector(`.landing .${this.state}.contact-form`).scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest"
      });
    },
  },
}
</script>

<template>
  <div class="landing">
    <header>
      <div class="container">
        <img src="@/views/Landing/images/logo.svg" alt="" @click="changeState('common')"/>

        <div class="group">
          <span class="contact" @click="scrollToBottom">Связаться с нами</span>
          <span class="entry" @click="this.$router.push('/authorization')">Войти</span>
        </div>
      </div>
    </header>

    <main>
      <div class="container" v-show="state === 'common'">
        <CommonContent @sendQuestion="sendQuestion" @changeState="changeState"/>
      </div>

      <div class="container" v-show="state === 'business'">
        <BusinessContent @sendQuestion="sendQuestion" @changeState="changeState"/>
      </div>

      <div class="container" v-show="state === 'department'">
        <DepartmentContent @sendQuestion="sendQuestion" @changeState="changeState"/>
      </div>

      <div class="container" v-show="state === 'trading'">
        <TradingContent @sendQuestion="sendQuestion" @changeState="changeState"/>
      </div>

      <button
          v-show="showScrollTopBtn"
          class="go-to-top"
          @click="scrollToTop"
      >
        <img src="@/views/Landing/images/icon/arrow_upward_alt_FILL0_wght400_GRAD0_opsz24.svg" alt=""/>
      </button>
    </main>

    <footer>
      <div class="container">

      </div>
    </footer>
  </div>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.landing {
  font-family: sans-serif;
  font-size: 16px;

  * {
    font-family: "Montserrat", sans-serif !important;
  }

  .container {
    width: 100%;

    .section-wrapper {
      width: 100%;

      .section {
        max-width: 1200px;
        margin: 0 auto;
      }
    }
  }

  header {
    background-color: var(--color-white);
    padding: 20px 0;
    border-bottom: 1px solid var(--landing-color-light-blue);

    .container {
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .group {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;

        & > * {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 12px 24px;
          border-radius: 50px;
          font-weight: bold;
          font-size: 0.8em;

          &.contact {
            color: var(--landing-color-dark-blue);

            &:hover {
              background-color: var(--landing-color-dark-blue);
              color: var(--color-white);
            }
          }

          &.entry {
            background-color: var(--landing-color-blue);
            color: var(--color-white);

            &:hover {
              background-color: var(--landing-color-dark-blue);
            }
          }
        }
      }

      img {
        cursor: pointer;
      }
    }
  }

  main {
    background: var(--color-white);
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: calc(100dvh - var(--landing-header-height));

    /* width */
    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: var(--landing-color-light-blue);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--landing-color-blue);
      border-radius: 0;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: var(--landing-color-dark-blue);
    }
  }

  .go-to-top {
    position: fixed;
    bottom: 10px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: none;
    background-color: var(--color-blue);
    color: #FFFFFF;
    cursor: pointer;
    z-index: 30;

    img {
      width: 50px;
      height: 50px;
      -webkit-filter: invert(100%);
      filter: invert(100%);
    }
  }
}

@media (max-width: 1024px) {
  .landing {
    header {
      padding: 10px 0;

      .container {
        justify-content: space-evenly;

        img {
          max-width: 150px;
        }

        .group {
          justify-content: flex-start;
          align-items: flex-end;
          flex-direction: column;
          gap: 5px;

          .entry {
            width: 100%;
          }
        }
      }
    }

    .container {
      padding: 0 10px;
    }
  }
}
</style>