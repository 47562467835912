import {serverError, serverFailedAccess} from "@/main";
import {API_URL} from "@/core/constants";

export default {
    actions: {
        async fetchModalDenail(ctx, payload) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/tickets/${payload.id}/reject`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
                    },
                    body: payload.payload
                }
            )
                .then((response) => {
                    if (response.ok) {
                        response.json().then((response) => {
                            ctx.commit('updateAppealsCardStatus', response.data.status)
                            ctx.commit('updateAppealsCardById', response.data)
                            ctx.commit('addNewChatMessage', response.data.messages)
                        })
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },
    }
}