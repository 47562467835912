import {serverError, serverFailedAccess} from "@/main";
import {API_URL} from "@/core/constants";

export default {
    state: {
        appealsCard: {
            user: {
                avatar: '',
                name: '',
                surname: '',
                middle_name: '',
                date: '',
                number: '',
                id: '',
            },
            status: '',
        },
        appealsCardInfo: {},
        appealsChat: [],
        appealsChatMessage: [],
    },
    getters: {
        getAppealsCard(state) {
            return state.appealsCard;
        },
        getAppealsCardInfo(state) {
            return state.appealsCardInfo;
        },
        getCardStatus(state) {
            return state.appealsCard.status;
        },
        getAppealsChat(state) {
            return [state.appealsChat];
        },
        getAppealsChatMessage(state) {
            return state.appealsChatMessage;
        },
    },
    mutations: {
        updateAppealsCard(state, appealsCard) {
            state.appealsCard = appealsCard;
        },
        updateAppealsCardInfo(state, appealsCard) {
            state.appealsCardInfo = appealsCard;
            state.appealsCardInfo.messages = state.appealsCardInfo.messages.reverse();
        },
        resetAppealsCardInfo(state) {
            state.appealsCardInfo = {};
        },
        updateCardStatus(state, cardStatus) {
            state.appealsCard.status = cardStatus;
        },
        updateAppealsChat(state, appealsChat) {
            state.appealsChat = appealsChat;
        },
        updateAppealsChatMessage(state, appealsChatMessage) {
            state.appealsChatMessage = appealsChatMessage;
        },
        addNewChatMessage(state, newChatMessage) {
            state.appealsChatMessage = newChatMessage;
            state.appealsCardInfo.messages = newChatMessage.reverse();
        },
        addNewMessageFromBackend(state, newMessageFromBackend) {
            state.appealsChatMessage.push(newMessageFromBackend);
            state.appealsCardInfo.messages.unshift(newMessageFromBackend);
        },
        updateAppealsCardStatus(state, newCardStatus) {
            return state.appealsCard.status = newCardStatus;
        },
    },
    actions: {
        async fetchUserAppealsCitizenCard(ctx, payload) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/tickets/${payload}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
                    }
                }
            )
                .then((response) => {
                    if (response.ok) {
                        response.json().then((response) => {
                            const appealsCitizen = response.data
                            ctx.commit('updateAppealsCardInfo', appealsCitizen)
                            ctx.commit('updateAppealsCard', appealsCitizen)
                            ctx.commit('updateCardStatus', appealsCitizen.status)

                            const appealsChat = response.data
                            const appealsChatMessage = response.data.messages
                            ctx.commit('updateAppealsChat', appealsChat)
                            ctx.commit('updateAppealsChatMessage', appealsChatMessage)
                        })
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async fetchChatMessage(ctx, payload) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/tickets/${payload.id}/send-message`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
                    },
                    body: payload.payload
                }
            )
                .then((response) => {
                    if (response.ok) {
                        response.json().then((response) => {
                            ctx.commit('addNewChatMessage', response.data.messages)
                        })
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async changeAppealsStatus(ctx, payload) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/tickets/${payload}/start-process`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
                    },

                }
            )
                .then((response) => {
                    if (response.ok) {
                        response.json().then((response) => {
                            ctx.commit('updateAppealsCardStatus', response.data.status)
                            ctx.commit('updateAppealsCardById', response.data)
                        })
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },
    }
}