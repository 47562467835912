import {serverError, serverFailedAccess} from "@/main";
import {API_URL} from "@/core/constants";

export default {
  state: {
    promoStatusList: [],

    promoFormTypeList: [],

    promoActivityTypeList: [],
    promoActivityCurrentPage: 0,
    promoActivityMaxPage: 0,

    promoDistributorsList: [],

    promoList: [],
    promoListCurrentPage: 0,
    promoListMaxPage: 0,

    promoDetails: [],

    draftResponse: false,

    innData: null,
    genericDoc: null,
  },
  getters: {
    getPromoStatusList(state) {
      return state.promoStatusList;
    },

    getPromoFormTypeList(state) {
      return state.promoFormTypeList;
    },

    getPromoActivityTypeList(state) {
      return state.promoActivityTypeList;
    },
    getPromoActivityCurrentPage(state) {
      return state.promoActivityCurrentPage;
    },
    getPromoActivityMaxPage(state) {
      return state.promoActivityMaxPage;
    },

    getPromoDistributorsList(state) {
      return state.promoDistributorsList;
    },

    getPromoList(state) {
      return state.promoList;
    },
    getPromoListCurrentPage(state) {
      return state.promoListCurrentPage;
    },
    getPromoListMaxPage(state) {
      return state.promoListMaxPage;
    },

    getPromoDetails(state) {
      return state.promoDetails;
    },

    getDraftResponse(state) {
      return state.draftResponse;
    },

    getInnData(state) {
      return state.innData;
    },
    getGenericDoc(state) {
      return state.genericDoc;
    },
  },
  mutations: {
    setPromoStatusList(state, list) {
      state.promoStatusList = list;
    },

    setPromoFormTypeList(state, list) {
      state.promoFormTypeList = list;
    },

    setPromoActivityTypeList(state, list) {
      state.promoActivityTypeList = list;
    },
    setPromoActivityCurrentPage(state, page) {
      state.promoActivityCurrentPage = page;
    },
    setPromoActivityMaxPage(state, page) {
      state.promoActivityMaxPage = page;
    },

    setPromoDistributorsList(state, list) {
      state.promoDistributorsList = list;
    },

    setPromoList(state, list) {
      state.promoList = list;
    },
    setPromoListCurrentPage(state, page) {
      state.promoListCurrentPage = page;
    },
    setPromoListMaxPage(state, page) {
      state.promoListMaxPage = page;
    },

    setPromoDetails(state, details) {
      state.promoDetails = details;
    },

    updatePromoData(state, details) {
      const index = state.promoList.findIndex(promo => promo.id == details.id);

      if (index !== -1) {
        // if (state.promoDetails.status === details.status) {
        //   state.promoList[index] = details;
        // } else {
        //   state.promoList.splice(index, 1);
        // }

        state.promoList[index] = details;
      }
    },

    resetPromoStore(state) {
      state.promoStatusList = [];

      state.promoFormTypeList = [];

      state.promoActivityTypeList = [];
      state.promoActivityCurrentPage = 0;
      state.promoActivityMaxPage = 0;

      state.promoDistributorsList = [];

      state.promoList = [];
      state.promoListCurrentPage = 0;
      state.promoListMaxPage = 0;

      state.promoDetails = [];
    },

    updateDraftResponse(state, draftResponse) {
      state.draftResponse = draftResponse;
    },

    setInnData(state, inn) {
      state.innData = inn;
    },
    setGenericDoc(state, doc) {
      state.genericDoc = doc;
    },
  },
  actions: {
    async fetchPromoStatusList(ctx) {
      await fetch(
          `${API_URL}managers/promotions/statuses`,
          {
            method: 'GET',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            }
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('setPromoStatusList', response.data);
              });
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async fetchPromoFormTypeList(ctx) {
      await fetch(
          `${API_URL}managers/promotions/form-types`,
          {
            method: 'GET',
            // headers: {
            //   Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            // }
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('setPromoFormTypeList', response.data);
              });
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async fetchPromoActivityTypeList(ctx, query) {
      if (ctx.state.promoActivityCurrentPage != 0) {
        ctx.commit('setPromoActivityCurrentPage', ctx.state.promoActivityCurrentPage + 1);
      }

      if (ctx.state.promoActivityCurrentPage <= ctx.state.promoActivityMaxPage) {
        await fetch(
            `${API_URL}managers/promotions/activity-types${query}`,
            {
              method: 'GET',
              // headers: {
              //   Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
              // }
            }
        )
            .then((response) => {
              if (response.ok) {
                response.json().then((response) => {
                  if (ctx.state.promoActivityCurrentPage == 0) {
                    ctx.commit('setPromoActivityTypeList', response.data.data);
                  } else {
                    ctx.commit('setPromoActivityTypeList', [...ctx.state.promoActivityTypeList, ...response.data.data]);
                  }

                  if (ctx.state.promoActivityCurrentPage == 0) {
                    ctx.commit('setPromoActivityMaxPage', response.data.last_page);
                    ctx.commit('setPromoActivityCurrentPage', 1);
                  }
                });
              } else {
                serverError(response);
              }
            })
            .catch(() => {
              serverFailedAccess();
            });
      }
    },
    // async fetchPromoDistributorsList(ctx) {
    //   await fetch(
    //       `${API_URL}managers/channels/${localStorage.channelId}/promotions/distributors?search=w`,
    //       {
    //         method: 'GET',
    //         headers: {
    //           Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
    //         }
    //       }
    //   )
    //       .then((response) => {
    //         if (response.ok) {
    //           response.json().then((response) => {
    //             ctx.commit('setPromoDistributorsList', response.data);
    //           });
    //         } else {
    //           serverError(response);
    //         }
    //       })
    //       .catch(() => {
    //         serverFailedAccess();
    //       });
    // },
    async fetchPromoList(ctx, query) {
      if (ctx.state.promoListCurrentPage != 0) {
        ctx.commit('setPromoListCurrentPage', ctx.state.promoListCurrentPage + 1);
      }

      if (ctx.state.promoListCurrentPage <= ctx.state.promoListMaxPage) {
        await fetch(
            `${API_URL}managers/channels/${localStorage.channelId}/promotions${query}`,
            {
              method: 'GET',
              headers: {
                Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
              }
            }
        )
            .then((response) => {
              if (response.ok) {
                response.json().then((response) => {
                  if (ctx.state.promoListCurrentPage == 0) {
                    ctx.commit('setPromoList', response.data.items);
                  } else {
                    ctx.commit('setPromoList', [...ctx.state.promoList, ...response.data.items]);
                  }

                  if (ctx.state.promoListCurrentPage == 0) {
                    ctx.commit('setPromoListMaxPage', response.data.last_page);
                    ctx.commit('setPromoListCurrentPage', 1);
                  }
                });
              } else {
                serverError(response);
              }
            })
            .catch(() => {
              serverFailedAccess();
            });
      }
    },
    async createPublicPromo(ctx, payload) {
      await fetch(
          `${API_URL}managers/promotions/${payload.token}`,
          {
            method: 'POST',
            body: payload.data
          }
      ).then((response) => {
        if (response.ok) {
          response.json().then((response) => {
            console.log(response)
          });
        } else {
          serverError(response);
        }
      })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async createPromo(ctx, payload) {
      await fetch(
          `${API_URL}managers/channels/${localStorage.channelId}/promotions`,
          {
            method: 'POST',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            },
            body: payload
          }
      ).then((response) => {
        if (response.ok) {
          response.json().then((response) => {
            const data = response.data;
            data.status = 'В ожидании';

            ctx.commit('setPromoList', [...[data], ...ctx.state.promoList]);
          });
        } else {
          serverError(response);
        }
      })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async createDraftPromo(ctx, payload) {
      await fetch(
          `${API_URL}managers/channels/${localStorage.channelId}/promotions/as-draft`,
          {
            method: 'POST',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            },
            body: payload
          }
      ).then((response) => {
        if (response.ok) {
          ctx.commit('updateDraftResponse', true);

          response.json().then((response) => {
            const data = response.data;
            data.status = 'Черновики';

            ctx.commit('setPromoList', [...[data], ...ctx.state.promoList]);
          });
        } else {
          ctx.commit('updateDraftResponse', false);

          serverError(response);
        }
      }).catch(() => {
        serverFailedAccess();
      });
    },
    async fetchPromoDetails(ctx, promoId) {
      await fetch(
          `${API_URL}managers/channels/${localStorage.channelId}/promotions/${promoId}`,
          {
            method: 'GET',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            }
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('setPromoDetails', response.data);
              });
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async updatePromo(ctx, payload) {
      await fetch(
          `${API_URL}managers/channels/${localStorage.channelId}/promotions/${payload.promoId}`,
          {
            method: 'POST',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            },
            body: payload.data
          }
      ).then((response) => {
        if (response.ok) {
          response.json().then((response) => {
            const data = response.data;

            ctx.commit('updatePromoData', data);
          });
        } else {
          serverError(response);
        }
      })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async updatePromoStats(ctx, payload) {
      await fetch(
          `${API_URL}managers/channels/${localStorage.channelId}/promotions/${payload.promoId}/update-state`,
          {
            method: 'POST',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            },
            body: payload.data
          }
      ).then((response) => {
        if (response.ok) {
          response.json().then((response) => {
            const data = response.data;

            ctx.commit('setPromoDetails', data);
          });
        } else {
          serverError(response);
        }
      })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async updatePromoDocument(ctx, payload) {
      await fetch(
          `${API_URL}managers/channels/${localStorage.channelId}/promotions/${payload.promoId}/upload-act`,
          {
            method: 'POST',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            },
            body: payload.data
          }
      ).then((response) => {
        if (response.ok) {
          response.json().then((response) => {
            const data = response.data;

            ctx.commit('setPromoDetails', data);
            ctx.commit('updatePromoData', data);
          });
        } else {
          serverError(response);
        }
      })
          .catch(() => {
            serverFailedAccess();
          });
    },

    async getDataByInn(ctx, inn) {
      await fetch(
          `${API_URL}managers/inn-info?inn=${inn}`,
          {
            method: 'GET',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            },
          }
      ).then((response) => {
        if (response.ok) {
          response.json().then((response) => {
            const data = response.data;

            ctx.commit('setInnData', data);
          });
        } else {
          serverError(response);
        }
      })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async genericDocument(ctx, payload) {
      await fetch(
          `${API_URL}managers/channels/${localStorage.channelId}/contracts`,
          {
            method: 'POST',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            },
            body: payload,
          }
      ).then((response) => {
        if (response.ok) {
          response.json().then((response) => {
            const data = response.data;

            ctx.commit('setGenericDoc', data);
          });
        } else {
          serverError(response);
        }
      })
          .catch(() => {
            serverFailedAccess();
          });
    },
  }
}