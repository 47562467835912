export default {
  state: {

  },
  getters: {

  },
  mutations: {

  },
  actions: {

  },
}