import roleModule from '@/store/modules/team/roleInTeam';
import {notify} from "@kyvg/vue3-notification";
import {serverError, serverFailedAccess} from "@/main";
import {API_URL} from "@/core/constants";

export default {
    state: {
        team: [],
        permissions: [],
        searchResultTeam: []
    },
    getters: {
        getTeam(state) {
            return state.team;
        },
        getPermissions(state) {
            if (state.permissions.length === 0) {
                if (localStorage.permissions) {
                    return JSON.parse(localStorage.permissions);
                }
            }

            return state.permissions;
        },
        getSearchResulTeam(state) {
            return state.searchResultTeam
        }
    },
    mutations: {
        updateTeam(state, team) {
            state.team = team;
        },
        addNewTeamPlayer(state, newTeamPlayer) {
            state.team = newTeamPlayer;
        },
        updatePermissions(state, permissions) {
            state.permissions = permissions;
            localStorage.permissions = JSON.stringify(permissions);
        },
        changeTeamPlayer(state, newData) {
            state.team.map((player, index) => {
                if (player.id === newData.id) {
                    state.team[index] = newData;
                }
            });
        },
        deletePlayer(state, playerId) {
            state.team.map((player, index) => {
                if (player.id === parseInt(playerId)) {
                    state.team.splice(index, 1)
                }
            });
        },
        updateSearchResulTeam(state, searchResult) {
            state.searchResultTeam = searchResult;
        }
    },
    actions: {
        async fetchTeam(ctx) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/teams`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`
                    }
                }
            )
                .then((response) => {
                    if (response.ok) {
                        response.json().then((response) => {
                            ctx.commit('updateTeam', response.data.items);
                        })
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async fetchPermissions(ctx) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/permissions`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`
                    }
                }
            )
                .then((response) => {
                    if (response.ok) {
                        response.json().then((response) => {
                            ctx.commit('updatePermissions', response.data);
                        })
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async addTeamPlayer(ctx, payload) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/teams`,
                {
                    method: 'POST',
                    body: payload,
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`
                    }
                }
            )
                .then((response) => {
                    if (response.ok) {
                        response.json().then((response) => {
                            ctx.commit('addNewTeamPlayer', response.data);
                        })
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async changeTeamPlayer(ctx, payload) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/teams/${payload.TeamID}`,
                {
                    method: 'POST',
                    body: payload.data,
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`
                    }
                }
            )
                .then((response) => {
                    if (response.ok) {
                        response.json().then((response) => {
                            ctx.commit('changeTeamPlayer', response.data);
                        })
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async deleteTeamPlayer(ctx, payload) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/teams/${payload}`,
                {
                    method: 'DELETE',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`
                    }
                }
            )
                .then((response) => {
                    if (response.ok) {
                        ctx.commit('deletePlayer', payload)
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async fetchSearchTeam(ctx, payload) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/teams/allowed-users?search=${payload}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
                    },

                }
            )
                .then((response) => {
                    if (response.ok) {
                        response.json().then((response) => {
                            ctx.commit('updateSearchResulTeam', response.data)
                        })
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async resetPasswordForTeam(ctx, payload) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/teams/${payload.id}/send-password`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
                    },
                    body: payload.data
                }
            )
                .then((response) => {
                    if (response.ok) {
                        notify({
                            type: 'success',
                            text: 'Новый пароль отправлен на почту',
                            speed: 1000,
                            duration: 5000
                        });
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        }
    },
    modules: {
        roleModule
    }
}