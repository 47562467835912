import {notify} from "@kyvg/vue3-notification";
import {serverError, serverFailedAccess} from "@/main";
import {API_URL} from "@/core/constants";
/* eslint-disable */
export default {
  state: {
    services: [],
    service: [],
    serviceCategories: [],
    currentPage: 0,
    maxPage: 0,
  },
  getters: {
    getServices(state) {
      return state.services;
    },
    getService(state) {
      return state.service;
    },
    getServiceCategories(state) {
      return state.serviceCategories;
    },
    getServicePage(state) {
      return state.currentPage;
    },
    getServiceMaxPage(state) {
      return state.maxPage;
    },
  },
  mutations: {
    updateServices(state, services) {
      state.services = services;
    },
    updateService(state, service) {
      state.service = service;
    },
    addNewService(state, newService) {
      state.services.unshift(newService);
    },
    deleteService(state, serviceId) {
      state.services.map((service, index) => {
        if (service.id === parseInt(serviceId)) {
          state.services.splice(index, 1)
        }
      });
    },
    changeService(state, changedService) {
      state.services.map((service, index) => {
        if (service.id === changedService.id) {
          state.services[index] = changedService;
        }
      });
    },
    updateServiceCategories(state, categories) {
      state.serviceCategories = categories;
    },
    setServiceCurrentPage(state, page) {
      state.currentPage = page;
    },
    setServiceMaxPage(state, page) {
      state.maxPage = page;
    },
  },
  actions: {
    async fetchServices(ctx, payload) {
      // if (ctx.state.currentPage != 0) {
      //   ctx.commit('setServiceCurrentPage', ctx.state.currentPage + 1);
      // }

      // if (ctx.state.currentPage <= ctx.state.maxPage) {
        await fetch(
            `${API_URL}managers/channels/${localStorage.channelId}/services${payload}`,
            {
              method: 'GET',
              headers: {
                Authorization: `${localStorage.token_type} ${localStorage.access_token}`
              }
            }
        )
            .then((response) => {
              if (response.ok) {
                response.json().then((response) => {
                  // if (ctx.state.currentPage == 0) {
                    ctx.commit('updateServices', response.data.items);
                  // } else {
                  //   ctx.commit('updateServices', [...ctx.state.services, ...response.data.items]);
                  // }

                  // if (ctx.state.currentPage == 0) {
                  //   ctx.commit('setServiceMaxPage', response.data.last_page);
                  //   ctx.commit('setServiceCurrentPage', 1);
                  // }
                })
              } else {
                serverError(response);
              }
            })
            .catch(() => {
              serverFailedAccess();
            });
      // }
    },
    async fetchService(ctx, payload) {
      await fetch(
          `${API_URL}managers/channels/${localStorage.channelId}/services/${payload}`,
          {
            method: 'GET',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`
            }
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('updateService', response.data);
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async createNewService(ctx, payload) {
      await fetch(
          `${API_URL}managers/channels/${localStorage.channelId}/services`,
          {
            method: 'POST',
            body: payload,
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`
            }
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('addNewService', response.data);
              });

              notify({
                type: 'success',
                text: 'Услуга успешно добавлена!',
                speed: 1000,
                duration: 5000
              });
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async changeService(ctx, payload) {
      await fetch(
          `${API_URL}managers/channels/${localStorage.channelId}/services/${payload.serviceID}`,
          {
            method: 'POST',
            body: payload.data,
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`
            }
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('changeService', response.data);

                notify({
                  type: 'success',
                  text: 'Услуга успешно изменена!',
                  speed: 1000,
                  duration: 5000
                });
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async deleteService(ctx, payload) {
      await fetch(
          `${API_URL}managers/channels/${localStorage.channelId}/services/${payload}`,
          {
            method: 'DELETE',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`
            }
          }
      )
          .then((response) => {
            if (response.ok) {
              ctx.commit('deleteService', payload)
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async fetchServiceCategories(ctx) {
      await fetch(
          `${API_URL}managers/channels/${localStorage.channelId}/services/categories`,
          {
            method: 'GET',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`
            }
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('updateServiceCategories', response.data);
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
  }
}