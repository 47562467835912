import {serverError, serverFailedAccess} from "@/main";
import {API_URL} from "@/core/constants";

export default {
    state: {
        cardList: [],
    },
    getters: {
        getCardList(state) {
            return state.cardList;
        },
    },
    mutations: {
        updateCardList(state, cardList) {
            state.cardList = cardList;
        },
    },
    actions: {
        async fetchUserListCitizenCard(ctx, payload) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/citizens/${payload}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
                    }
                }
            )
                .then((response) => {
                    if (response.ok) {
                        response.json().then((response) => {
                            const appealsCitizen = response.data
                            ctx.commit('updateCardList', appealsCitizen.user)
                        })
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },

    }
}