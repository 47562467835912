export const API_URL = process.env.VUE_APP_PATH_START;

export const ASSISTANT_NAME = 'ТUЛЛUК';

import neft from '@/assets/img/promo/neft.jpg';
import azbuka from '@/assets/img/promo/azbuka2411.jpg';

export const PROMO_DATA = [
  {
    id: 0,
    channelId: 405,
    link: '',
    image: azbuka,
    description: 'Азбука',
  },
  {
    id: 1,
    channelId: 380,
    link: '',
    image: neft,
    description: 'ТУЙМААДА НЕФТЬ',
  },
];
export const FEED_PROMO_DATA = [];

export const KZ_MOB_OPERATOR = [
  '700',
  '701',
  '702',
  '703',
  '704',
  '705',
  '706',
  '707',
  '708',
  '709',
  '747',
  '750',
  '751',
  '760',
  '761',
  '762',
  '763',
  '764',
  '771',
  '775',
  '776',
  '777',
  '778',
];