import router from "@/router";
import {serverError, serverFailedAccess, startSocket} from "@/main";
import {notify} from "@kyvg/vue3-notification";
import {API_URL} from "@/core/constants";

export default {
  state: {
    userOnline: [],
    userForRegister: [],
    verfSmsId: '',
    statusCheckSms: true,
    registerFinish: false,
    managersList: [],
    commonUserData: {},
  },
  getters: {
    getUserOnline(state) {
      if (state.userOnline.length === 0) {
        if (localStorage.userOnline) {
          return JSON.parse(localStorage.userOnline);
        }
      }

      return state.userOnline;
    },
    getUserForRegister(state) {
      return state.userForRegister;
    },
    thisUserFromKZ(state) {
      if (state.userOnline.length === 0) {
        if (localStorage.userOnline) {
          const address = JSON.parse(localStorage.userOnline).address;

          return address.includes('Казахстан');
        }
      }

      return state.userOnline.address.includes('Казахстан');
    },
    thisUserBlogger(state) {
      return state.userOnline.length
          ? state.userOnline.is_blogger
          : localStorage.userOnline
              ? JSON.parse(localStorage.userOnline).is_blogger
              : false;
    },
    thisUserAdministrative(state) {
      return state.userOnline.length
          ? state.userOnline.is_administrative
          : localStorage.userOnline
              ? JSON.parse(localStorage.userOnline).is_administrative
              : false;
    },
    getVerfSmsId(state) {
      return state.verfSmsId;
    },
    getStatusCheckSms(state) {
      return state.statusCheckSms;
    },
    getManagersList(state) {
      return state.managersList;
    },
    getRegisterFinish(state) {
      return state.registerFinish;
    },
    getUserData(state) {
      return state.commonUserData;
    }
  },
  mutations: {
    updateUserOnline(state, userData) {
      state.userOnline = userData;
      localStorage.userOnline = JSON.stringify(userData);
    },
    updateUserForRegister(state, userData) {
      state.userForRegister = userData;
    },
    updateVerfSmsId(state, smsId) {
      state.verfSmsId = smsId;
    },
    updateStatusCheckSms(state, status) {
      state.statusCheckSms = status;
    },
    updateManagersList(state, list) {
      state.managersList = list;
    },
    registerFinish(state, register) {
      state.registerFinish = register;
    },
    updateUserData(state, user) {
      state.commonUserData = user;
    }
  },
  actions: {
    async authorization(ctx, payload) {
      await fetch(
          `${API_URL}managers/auth/login`,
          {
            method: 'POST',
            body: payload
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                localStorage.access_token = response.data.access_token;
                localStorage.token_type = response.data.token_type;

                startSocket();

                ctx.commit('updateUserOnline', response.data.manager);

                this.dispatch('fetchChannels');
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async logoutUser() {
      await fetch(
          `${API_URL}managers/logout`,
          {
            method: 'POST',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
              token: localStorage.access_token
            }
          }
      )
          .then((response) => {
            if (response.ok) {
              localStorage.clear();

              setTimeout(() => {
                router.push('/authorization');
              }, 100);
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async getUserForRegister(ctx) {
      await fetch(
          `${API_URL}user`,
          {
            method: 'GET',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
              token: localStorage.access_token
            }
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('updateUserForRegister', response.data);
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async changeLoginData(ctx, payload) {
      await fetch(
          `${API_URL}managers/update-auth-data`,
          {
            method: 'POST',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
              token: localStorage.access_token
            },
            body: payload
          }
      )
          .then((response) => {
            if (response.ok) {
              notify({
                type: 'success',
                text: 'Данные успешно обновлены!',
                speed: 1000,
                duration: 5000
              });
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async registerByPhone(ctx, payload) {
      await fetch(
          `${API_URL}registration`,
          {
            method: 'POST',
            body: payload
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                localStorage.access_token = response.data.access_token;
                localStorage.token_type = response.data.token_type;

                ctx.commit('registerFinish', true);
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async sendSmsRequest(ctx, payload) {
      await fetch(
          `${API_URL}code/send?phone=${payload}`,
          {
            method: 'GET',
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('updateVerfSmsId', response.data.data.id);
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async checkSmsRequest(ctx, payload) {
      ctx.commit('updateStatusCheckSms', true);

      await fetch(
          `${API_URL}code/check`,
          {
            method: 'POST',
            body: payload
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                localStorage.access_token = response.data.access_token;
                localStorage.token_type = response.data.token_type;

                ctx.commit('updateStatusCheckSms', true);
              })
            } else {
              ctx.commit('updateStatusCheckSms', false);
              // serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async fetchManagersList(ctx) {
      await fetch(
          `${API_URL}managers/as-users/managers`,
          {
            method: 'GET',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            }
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('updateManagersList', response.data);
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async chooseManager(ctx, payload) {
      await fetch(
          `${API_URL}managers/as-users/managers/${payload}`,
          {
            method: 'POST',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            }
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                localStorage.access_token = response.data.access_token;
                localStorage.token_type = response.data.token_type;

                startSocket();

                ctx.commit('updateUserOnline', response.data.manager);

                this.dispatch('fetchChannels');
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async fetchUserData(ctx) {
      await fetch(
          `${API_URL}user`,
          {
            method: 'GET',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            }
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('updateUserData', response.data);
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
  }
}