<template>
  <CustomModal v-if="!showError" class="blogger-modal" @closeModal="switchErrorModal">
    <template v-slot:header>
      <span class="title">Введите идентификатор</span>
      <button class="close-modal" @click="$emit('switchCreateBlogLayout')"><i class="material-icons">close</i></button>
    </template>
    <template v-slot:body>
      <span class="info">
        Для регистрации канала, пожалуйста, введите идентификатор Вашего профиля.
        Вы сможете найти его в настройках профиля в мобильном приложении
      </span>
      <br>
      <br>
      <input class="index-input secretID" placeholder="Введите идентификатор пользователя">
      <br>
      <br>
      <div class="img-block">
        <img src="@/assets/img/newRegister/screen_blogger2.svg">
        <img src="@/assets/img/newRegister/screen_blogger1.svg">
      </div>
    </template>
    <template v-slot:footer>
      <button class="btn-danger" @click="$emit('switchCreateBlogLayout')">Отмена</button>
      <button class="btn-success" @click="validateCheck">Далее</button>
    </template>
  </CustomModal>

  <CustomModal v-if="showError" class="blogger-error" @closeModal="switchErrorModal">
    <template v-slot:header>
      <span class="title danger">Ошибка</span>
      <button class="close-modal" @click="switchErrorModal"><i class="material-icons">close</i></button>
    </template>
    <template v-slot:body>
      <span class="info">
        Введите все необходимые данные
      </span>
    </template>
  </CustomModal>
</template>

<script>
import CustomModal from '@/components/CommonComponents/custom-modal';
import {mapActions, mapGetters} from "vuex";

export default {
  data: () => ({
    showError: false,
  }),
  name: 'CreateBlogger',
  components: {
    CustomModal
  },
  emits: [
    'switchCreateBlogLayout'
  ],
  mounted() {
    if (this.getUserData?.secret_id !== undefined) {
      this.register(this.getUserData?.secret_id);
    }
  },
  computed: {
    ...mapGetters(
        [
          'getUserData',
        ]
    ),
    switchErrorModal() {
      return () => {
        this.showError = !this.showError;
      }
    }
  },
  methods: {
    ...mapActions(
        [
          'registerBlogger'
        ]
    ),
    validateCheck() {
      if (document.querySelector('.index-input').value === '') {
        this.switchErrorModal();
      } else {
        this.register(document.querySelector('.secretID').value);
      }
    },
    register(secret_id) {
      const payload = new FormData();

      payload.append('secret_id', secret_id);

      this.registerBlogger(payload);
    },
  }
}
</script>

<style lang="scss" scoped>
.index-input {
  border: 1px solid var(--color-black) !important;
  color: var(--color-black) !important;

  &::placeholder {
    color: var(--color-red) !important;
  }
}

.title.danger {
  color: var(--figma-color-red);
}

.info {
  font-family: 'SF Pro Text Regular';
  color: var(--figma-color-dark-grey);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
}

.img-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  img {
    width: 48%;
  }
}

input, button {
  font-family: 'SF Pro Display Regular';
}

@media (max-width: 1540px) {
  .img-block {
    img {
      width: 217px;
    }
  }

  .info {
    font-size: 12px;
  }

  .index-input.secretID {
    height: 40px !important;
  }

  .btn-success, .btn-danger {
    height: 45px !important;
  }
}

@media (max-width: 1152px) {
  img {
    width: 240px;
  }
}

@media (max-width: 680px) {
  .img-block {
    flex-direction: column;

    img {
      width: 270px;
      margin: 10px;
    }
  }
}
</style>