export function validPhone(phone) {
    let validNamePattern = new RegExp("^((8|\\+7)[\\- ]?)?(\\(?\\d{3}\\)?[\\- ]?)?[\\d\\- ]{10}$");
    if (validNamePattern.test(phone)) {
        return true;
    }
    return false;
}

export function validFio(fio) {
    let validFioPattern = new RegExp("^[А-ЯЁ][а-яё]{1,}([-][А-ЯЁ][а-яё]{1,})?\\s[А-ЯЁ][а-яё]{1,}\\s[А-ЯЁ][а-яё]{1,}$");
    if (validFioPattern.test(fio)) {
        return true;
    }
    return false;
}

export function validName(name) {
    let validNamePattern = new RegExp("^([А-Я]{1}[а-яё]{1,23}|[A-Z]{1}[a-z]{1,23})$");
    if (validNamePattern.test(name)) {
        return true;
    }
    return false;
}

export function validOnliNumber(number) {
    let validNumberPattern = new RegExp("^\\d+$");
    if (validNumberPattern.test(number)) {
        return true;
    }
    return false;
}

export function validOnliLetters(letter) {
    let validLetterPattern = new RegExp("^[A-zА-яЁё]+$");
    if (validLetterPattern.test(letter)) {
        return true;
    }
    return false;
}