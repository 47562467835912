import {notify} from "@kyvg/vue3-notification";
import {serverError, serverFailedAccess} from "@/main";
import axios from "axios";
import {API_URL} from "@/core/constants";

export default {
  state: {
    posts: [],
    postsTotalCount: null,
    postsPage: 1,
    postsMaxPage: 0,
    postUploadProgress: 0,
    promoCategory: [],
  },
  getters: {
    getPosts(state) {
      return state.posts;
    },
    getPostsTotalCount(state) {
      return state.postsTotalCount;
    },
    getPostUploadProgress(state) {
      return state.postUploadProgress;
    },
    getPromoCategory(state) {
      return state.promoCategory;
    },
  },
  mutations: {
    updatePosts(state, posts) {
      state.posts = posts;
    },
    updatePostsTotalCount(state, count) {
      state.postsTotalCount = count;
    },
    addNewPost(state, post) {
      state.posts.unshift(post);
    },
    updatePost(state, changedPost) {
      state.posts.map((post, index) => {
        if (post.id === parseInt(changedPost.id)) {
          state.posts[index] = changedPost;
        }
      });
    },
    deletePost(state, postId) {
      state.posts.map((post, index) => {
        if (post.id === parseInt(postId)) {
          state.posts.splice(index, 1)
        }
      });

      state.postsTotalCount = state.posts.length;
    },
    setPostPage(state, page) {
      state.postsPage = page
    },
    setPostCard(state, post) {
      state.posts = post;
    },
    setPostMaxPage(state, page) {
      state.postsMaxPage = page;
    },
    setPostUploadProgress(state, progress) {
      state.postUploadProgress = progress;
    },
    updatePromoCategory(state, category) {
      state.promoCategory = category;
    },
  },
  actions: {
    async fetchPromoCategory(ctx) {
      await fetch(
          `${API_URL}managers/publications/categories`,
          {
            method: 'GET',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`
            }
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('updatePromoCategory', response.data.items);
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async fetchPosts(ctx) {
      await fetch(
          `${API_URL}managers/channels/${localStorage.channelId}/posts`,
          {
            method: 'GET',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`
            }
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('updatePosts', response.data.items);
                ctx.commit('updatePostsTotalCount', response.data.total);
                ctx.commit('setPostMaxPage', response.data.last_page);
                ctx.commit('setPostPage', 1);
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async fetchMorePosts(ctx, payload) {
      ctx.commit('setPostPage', ctx.state.postsPage + 1)
      if (ctx.state.postsPage <= ctx.state.postsMaxPage) {
        await fetch(
            `${API_URL}managers/channels/${localStorage.channelId}/posts?page=${ctx.state.postsPage}&search=${payload.searchValue}&sort=${payload.sortParam}`,
            {
              method: 'GET',
              headers: {
                Authorization: `${localStorage.token_type} ${localStorage.access_token}`
              }
            }
        )
            .then((response) => {
              if (response.ok) {
                response.json().then((response) => {
                  ctx.commit('setPostCard', [...ctx.state.posts, ...response.data.items]);
                  ctx.commit('updatePostsTotalCount', response.data.total);
                })
              } else {
                serverError(response);
              }
            })
            .catch(() => {
              serverFailedAccess();
            });
      }
    },
    async createNewPost(ctx, payload) {
      ctx.commit('setPostUploadProgress', 0);

      axios.post(`${API_URL}managers/channels/${localStorage.channelId}/posts`,
          payload,
          {
            method: 'POST',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`
            },
            onUploadProgress: function (progressEvent) {
              ctx.commit('setPostUploadProgress', parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100)));
            }.bind(this)
          }
      )
          .then((response) => {
            if (response.status === 200) {
              ctx.commit('addNewPost', response.data.data);
            } else {
              serverError(response);
            }
          })
          .catch((response) => {
            if (response.response.status === 422) {
              serverError(response.response.data);
            } else {
              serverFailedAccess();
            }
          });
    },
    async changePost(ctx, payload) {
      ctx.commit('setPostUploadProgress', 0);

      axios.post(`${API_URL}managers/channels/${localStorage.channelId}/posts/${payload.postId}`,
          payload.data,
          {
            method: 'POST',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`
            },
            onUploadProgress: function (progressEvent) {
              ctx.commit('setPostUploadProgress', parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100)));
            }.bind(this)
          }
      )
          .then((response) => {
            if (response.status === 204) {
              notify({
                type: 'success',
                text: 'Пост успешно удален!',
                speed: 1000,
                duration: 5000
              });

              ctx.commit('deletePost', payload.postId);
            } else if (response.status === 200) {
              ctx.commit('updatePost', response.data.data);
            } else {
              serverError(response);
            }
          })
          .catch((response) => {
            if (response.response.status === 422) {
              serverError(response.response.data);
            } else {
              serverFailedAccess();
            }
          });
    },
    async addCommentPost(ctx, payload) {
      await fetch(
          `${API_URL}managers/channels/${localStorage.channelId}/posts/${payload.postId}/comments/add-comment`,
          {
            method: 'POST',
            body: payload.data,
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`
            }
          }
      )
          .then((response) => {
            if (response.ok) {
              this.dispatch('fetchPosts');
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async deleteCommentPost(ctx, payload) {
      await fetch(
          `${API_URL}managers/channels/${localStorage.channelId}/posts/${payload.postId}/comments/${payload.commentId}`,
          {
            method: 'POST',
            body: payload.data,
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`
            }
          }
      )
          .then((response) => {
            if (response.ok) {
              notify({
                type: 'success',
                text: 'Комментарий успешно удален!',
                speed: 1000,
                duration: 5000
              });

              this.dispatch('fetchPosts');
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async fetchSortPost(ctx, payload) {
      await fetch(
          `${API_URL}managers/channels/${localStorage.channelId}/posts?sort=${payload}`,
          {
            method: 'GET',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`
            }
          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('updatePosts', response.data.items);
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async fetchSearchMyChanel(ctx, payload) {
      await fetch(
          `${API_URL}managers/channels/${localStorage.channelId}/posts?search=${payload}`,
          {
            method: 'GET',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
            },

          }
      )
          .then((response) => {
            if (response.ok) {
              response.json().then((response) => {
                ctx.commit('updatePosts', response.data.items);
              })
            } else {
              serverError(response);
            }
          })
          .catch(() => {
            serverFailedAccess();
          });
    },
    async createNewArticle(ctx, payload) {
      ctx.commit('setPostUploadProgress', 0);

      await axios.post(`${API_URL}managers/channels/${localStorage.channelId}/articles`,
          payload,
          {
            method: 'POST',
            headers: {
              Authorization: `${localStorage.token_type} ${localStorage.access_token}`
            },
            onUploadProgress: function (progressEvent) {
              const progress = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));

              if (progress < 98) {
                ctx.commit('setPostUploadProgress', progress);
              }
            }.bind(this)
          }
      )
          .then((response) => {
            if (response.status === 200) {
              ctx.commit('setPostUploadProgress', 99);
            } else {
              serverError(response);
            }
          })
          .catch((response) => {
            if (response.response.status === 422) {
              serverError(response.response.data);
            } else {
              serverFailedAccess();
            }
          });
    },
  }
}