import {serverError, serverFailedAccess} from "@/main";
import {API_URL} from "@/core/constants";

export default {
    state: {
        appealsCitizen: [],
        appealsCitizenByChannel: [],
        appealsPage: 1,
        appealsByChannelPage: 1,
        appealsMaxPage: 0,
        appealsByChannelMaxPage: 0,
        types: [],
    },
    getters: {
        getAppealsCitizen(state) {
            return state.appealsCitizen;
        },
        getAppealsCitizenByChannel(state) {
            return state.appealsCitizenByChannel;
        },
        getAppealsCitizenTypes(state) {
            return state.types;
        },
    },
    mutations: {
        updateAppealsCitizen(state, appealsCitizen) {
            state.appealsCitizen = appealsCitizen;
        },
        updateAppealsCitizenByChannel(state, appealsCitizen) {
            state.appealsCitizenByChannel = appealsCitizen;
        },
        updateAppealsCardById(state, newCardInfo) {
            state.appealsCitizen.map((citizen, index) => {
                if (citizen.id === newCardInfo.id) {
                    state.appealsCitizen[index] = newCardInfo
                }
            })

            state.appealsCitizenByChannel.map((citizen, index) => {
                if (citizen.id === newCardInfo.id) {
                    state.appealsCitizenByChannel[index] = newCardInfo
                }
            })
        },
        addNewTicketFromBackend(state, newTicketFromBackend) {
            state.appealsCitizen.unshift(newTicketFromBackend);
            state.appealsCitizenByChannel.unshift(newTicketFromBackend);
        },
        setAppealsPage(state, page) {
            state.appealsPage = page
        },
        setAppealsByChannelPage(state, page) {
            state.appealsByChannelPage = page
        },
        setAppealsCard(state, card) {
            state.appealsCitizen = card;
        },
        setAppealsByChannelCard(state, card) {
            state.appealsCitizenByChannel = card;
        },
        setAppealsMaxPage(state, page) {
            state.appealsMaxPage = page
        },
        setAppealsByChannelMaxPage(state, page) {
            state.appealsByChannelMaxPage = page
        },
        setTypes(state, types) {
            state.types = types;
        },
    },
    actions: {
        async fetchAppealsCitizen(ctx) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/tickets?status=''&type_id=2`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
                    }
                }
            ).then((response) => {
                if (response.ok) {
                    response.json().then((response) => {
                        const appealsCitizen = response.data.items;

                        ctx.commit('updateAppealsCitizen', appealsCitizen);
                        ctx.commit('setAppealsMaxPage', response.data.last_page);
                        ctx.commit('setAppealsPage', 1);
                    })
                } else {
                    serverError(response);
                }
            }).catch(() => {
                serverFailedAccess();
            });
        },
        async fetchMoreAppealsCitizen(ctx, payload) {
            ctx.commit('setAppealsPage', ctx.state.appealsPage + 1)
            if (ctx.state.appealsPage <= ctx.state.appealsMaxPage) {
                await fetch(
                    `${API_URL}managers/channels/${localStorage.channelId}/tickets?page=${ctx.state.appealsPage}&search=${payload.search}&sort_name=${payload.sortName}&sort_date=${payload.sortDate}&sort_number=${payload.sortNumber}&sort_status=${payload.sortStatus}&status=${payload.sortParam}&${payload.typeId ? `type_id=${payload.typeId}` : ''}`,
                    {
                        method: 'GET',
                        headers: {
                            Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
                        }
                    }
                ).then((response) => {
                    if (response.ok) {
                        response.json().then((response) => {
                            if (ctx.state.appealsPage === 1) {
                                ctx.commit('setAppealsCard', []);
                            }

                            ctx.commit('setAppealsMaxPage', response.data.last_page);
                            ctx.commit('setAppealsCard', [...ctx.state.appealsCitizen, ...response.data.items]);
                        })
                    } else {
                        serverError(response);
                    }
                }).catch(() => {
                    serverFailedAccess();
                });
            }
        },

        async fetchAppealsCitizenByChannel(ctx) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/tickets/stored-by-channel?type_id=2`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
                    }
                }
            ).then((response) => {
                if (response.ok) {
                    response.json().then((response) => {
                        const appealsCitizen = response.data.items;

                        ctx.commit('updateAppealsCitizenByChannel', appealsCitizen);
                        ctx.commit('setAppealsByChannelMaxPage', response.data.last_page);
                        ctx.commit('setAppealsByChannelPage', 1);
                    })
                } else {
                    serverError(response);
                }
            }).catch(() => {
                serverFailedAccess();
            });
        },
        async fetchMoreAppealsCitizenByChannel(ctx, payload) {
            ctx.commit('setAppealsByChannelPage', ctx.state.appealsByChannelPage + 1)
            if (ctx.state.appealsByChannelPage <= ctx.state.appealsByChannelMaxPage) {
                await fetch(
                    `${API_URL}managers/channels/${localStorage.channelId}/tickets/stored-by-channel?page=${ctx.state.appealsByChannelPage}&search=${payload.search}&sort_name=${payload.sortName}&sort_date=${payload.sortDate}&sort_number=${payload.sortNumber}&sort_status=${payload.sortStatus}&status=${payload.sortParam}&${payload.typeId ? `type_id=${payload.typeId}` : ''}`,
                    {
                        method: 'GET',
                        headers: {
                            Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
                        }
                    }
                ).then((response) => {
                    if (response.ok) {
                        response.json().then((response) => {
                            if (ctx.state.appealsPage === 1) {
                                ctx.commit('setAppealsByChannelCard', []);
                            }

                            ctx.commit('setAppealsByChannelMaxPage', response.data.last_page);
                            ctx.commit('setAppealsByChannelCard', [...ctx.state.appealsCitizenByChannel, ...response.data.items]);
                        })
                    } else {
                        serverError(response);
                    }
                }).catch(() => {
                    serverFailedAccess();
                });
            }
        },


        async fetchTypesAppealsCitizen(ctx) {
            await fetch(
              `${API_URL}managers/channels/${localStorage.channelId}/tickets/types`,
              {
                  method: 'GET',
                  headers: {
                      Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
                  }
              }
            ).then((response) => {
                if (response.ok) {
                    response.json().then((response) => {
                        ctx.commit('setTypes', response.data);
                    })
                } else {
                    serverError(response);
                }
            }).catch(() => {
                serverFailedAccess();
            });
        },
        async fetchSortAppealsCitizen(ctx, payload) {
            ctx.commit('setAppealsPage', 1)
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/tickets?page=${ctx.state.appealsPage}&search=${payload.search}&sort_name=${payload.sortName}&sort_date=${payload.sortDate}&sort_number=${payload.sortNumber}&sort_status=${payload.sortStatus}&status=${payload.sortParam}&${payload.typeId ? `type_id=${payload.typeId}` : ''}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
                    },

                }
            ).then((response) => {
                if (response.ok) {
                    response.json().then((response) => {
                        const appealsCitizen = response.data.items
                        ctx.commit('updateAppealsCitizen', appealsCitizen)
                        ctx.commit('setAppealsMaxPage', response.data.last_page)
                    })
                } else {
                    serverError(response);
                }
            }).catch(() => {
                serverFailedAccess();
            });
        },
    }
}