import {serverError, serverFailedAccess} from "@/main";
import {API_URL} from "@/core/constants";

export default {
    state: {
        listCitizen: [],
        listPage: 1,
        listMaxPage: 0,
    },
    getters: {
        getListCitizen(state) {
            return state.listCitizen;
        },
    },
    mutations: {
        updateListCitizen(state, listCitizen) {
            state.listCitizen = listCitizen;
        },
        setListPage(state, page) {
            state.listPage = page
        },
        setListCard(state, card) {
            state.listCitizen = card;
        },
        setListMaxPage(state, page) {
            state.listMaxPage = page
        },
    },
    actions: {
        async fetchListCitizenCitizen(ctx) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/citizens`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
                    }
                }
            )
                .then((response) => {
                    if (response.ok) {
                        response.json().then((response) => {
                            const listCitizen = response.items
                            ctx.commit('updateListCitizen', listCitizen)
                            ctx.commit('setListMaxPage', response.last_page)
                            ctx.commit('setListPage', 1)
                        })
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async fetchMoreListCitizenCitizen(ctx, payload) {
            ctx.commit('setListPage', ctx.state.listPage + 1)
            if (ctx.state.listPage <= ctx.state.listMaxPage) {
                await fetch(
                    `${API_URL}managers/channels/${localStorage.channelId}/citizens?page=${ctx.state.listPage}&search=${payload.searchValue}&sort_name=${payload.sortName}&sort_date=${payload.sortDate}`,
                    {
                        method: 'GET',
                        headers: {
                            Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
                        }
                    }
                )
                    .then((response) => {
                        if (response.ok) {
                            response.json().then((response) => {
                              if (ctx.state.listPage === 1) {
                                ctx.commit('setListCard', []);
                              }

                                ctx.commit('setListCard', [...ctx.state.listCitizen, ...response.items])
                            })
                        } else {
                            serverError(response);
                        }
                    })
                    .catch(() => {
                        serverFailedAccess();
                    });
            }
        },
        async fetchSortListCitizenCitizen(ctx, payload) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/citizens?sort_name=${payload.sortName}&sort_date=${payload.sortDate}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
                    },

                }
            )
                .then((response) => {
                    if (response.ok) {
                        response.json().then((response) => {
                            const listCitizen = response.items
                            ctx.commit('updateListCitizen', listCitizen)
                        })
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async fetchSearchListCitizenCitizen(ctx, payload) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/citizens?search=${payload}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
                    },

                }
            )
                .then((response) => {
                    if (response.ok) {
                        response.json().then((response) => {
                            ctx.commit('updateListCitizen', response.items)
                        })
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async createNewApply(ctx, payload) {
          await fetch(
              `${API_URL}managers/channels/${localStorage.channelId}/tickets`,
              {
                method: 'POST',
                headers: {
                  Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
                },
                body: payload,
              }
          )
              .then((response) => {
                console.log(response);
              })
              .catch(() => {
                serverFailedAccess();
              });
        },
    }
}