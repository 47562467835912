import {serverError, serverFailedAccess} from "@/main";
import {API_URL} from "@/core/constants";

export default {
    state: {
        roleInTeam: []
    },
    getters: {
        getRoles(state) {
            return state.roleInTeam;
        }
    },
    mutations: {
        updateRole(state, roleInTeam) {
            state.roleInTeam = roleInTeam;
        }
    },
    actions: {
        async fetchRoles(ctx) {
            await fetch(
                `${API_URL}managers/channels/roles`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`
                    }
                }
            )
                .then((response) => {
                    if (response.ok) {
                        response.json().then((response) => {
                            ctx.commit('updateRole', response.data)
                        });
                    } else {
                        serverError(response);
                    }
                })
                .catch(() => {
                    serverFailedAccess();
                });
        }
    }
}