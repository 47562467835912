import {serverError, serverFailedAccess} from "@/main";
import {API_URL} from "@/core/constants";

export default {
    state: {
        notifyList: [],
        unreadMessageCount: 0,
        unreadPrivateMessageCount: 0,
        unreadAssistantMessageCount: 0,
    },
    getters: {
        getNotifyList(state) {
            return state.notifyList;
        },
        getUnreadMessageCount(state) {
            return state.unreadMessageCount;
        },
        getUnreadPrivateMessageCount(state) {
            return state.unreadPrivateMessageCount + state.unreadAssistantMessageCount;
        },
        getUnreadAssistantMessageCount(state) {
            return state.unreadAssistantMessageCount;
        },
    },
    mutations: {
        updateNotifyList(state, notifyList) {
            state.notifyList = notifyList;
        },
        addCountMessage(state, count = 1) {
            state.unreadMessageCount = count;
        },
        addCountPrivateMessage(state, count = 1) {
            state.unreadPrivateMessageCount = count;
        },
        addCountAssistantMessage(state, count = 1) {
            state.unreadAssistantMessageCount = count;
        },
    },
    actions: {
        async fetchNotifyList(ctx) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/notifications`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
                    }
                }
            ).then((response) => {
                if (response.ok) {
                    response.json().then((response) => {
                        ctx.commit('updateNotifyList', response.data);
                    })
                } else {
                    serverError(response);
                }
            })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async fetchAllUnreadMessages(ctx) {
            await fetch(
                `${API_URL}managers/rooms/${localStorage.channelId}/unread-messages-count`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
                    }
                }
            ).then((response) => {
                if (response.ok) {
                    response.json().then((response) => {
                        ctx.commit('addCountMessage', response.data?.unreadMessages || 0);
                        ctx.commit('addCountPrivateMessage', response.data?.unreadPrivateMessages || 0);
                        ctx.commit('addCountAssistantMessage', response.data?.assistantMessagesCount || 0);
                    })
                } else {
                    serverError(response);
                }
            })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async readNotify(ctx, payload) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/notifications/${payload}/mark-read`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
                    }
                }
            ).then((response) => {
                if (response.ok) {
                    ctx.dispatch('fetchNotifyList');
                } else {
                    serverError(response);
                }
            })
                .catch(() => {
                    serverFailedAccess();
                });
        },
        async readNotifyGroup(ctx, payload) {
            await fetch(
                `${API_URL}managers/channels/${localStorage.channelId}/notifications/${payload.notifyId}/mark-read-by-group`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
                    },
                    body: payload.data
                }
            ).then((response) => {
                if (response.ok) {
                    ctx.dispatch('fetchNotifyList');
                } else {
                    serverError(response);
                }
            })
                .catch(() => {
                    serverFailedAccess();
                });
        },
    }
}